// services.constants.ts
import {Roofing, CleaningServices, CleanHands, SolarPower, Window} from '@mui/icons-material';

export type Service = {
    name: string;
    label: string;
    icon: React.ReactNode;
    defaultPrice: number;
    selected: boolean;
    price: number;

};

export type ServiceState = Record<string, Service>;

export const INITIAL_SERVICES: ServiceState = {
    roofClean: {
        name: "Roof Clean",
        label: "Roof Clean",
        icon: <Roofing />,
        defaultPrice: 0,
        selected: false,
        price: 0,
    },
    gutterClean: {
        name: "Gutter Clean",
        label: "Gutter Clean",
        icon: <CleaningServices />,
        defaultPrice: 0,
        selected: false,
        price: 0,
    },
    solarPanelClean: {
        name: "Solar Panel Clean",
        label: "Solar Panel Clean",
        icon: <SolarPower />,
        defaultPrice: 0,
        selected: false,
        price: 0,
    },
    faciasSoffitsAndOutsideGutters: {
        name: "Facias & Soffits and Outside of Gutters",
        label: "Facias & Soffits and Outside of Gutters",
        icon: <CleaningServices />,
        defaultPrice: 0,
        selected: false,
        price: 0,
    },
    windowSillsAndUPVCDoors: {
        name: "Windows, Sills, and UPVC Doors",
        label: "Windows, Sills, and UPVC Doors",
        icon: <Window />,
        defaultPrice: 0,
        selected: false,
        price: 0,

    },
    conservatoryGutterClean: {
        name: "Conservatory Gutter Clean",
        label: "Conservatory Gutter Clean",
        icon: <CleanHands />,
        defaultPrice: 0,
        selected: false,
        price: 0,
    },
    conservatoryWindowsRoofAndUPVCDoors: {
        name: "Conservatory Windows, Roof and all UPVc",
        label: "Conservatory Windows, Roof and all UPVc",
        icon: <CleanHands />,
        defaultPrice: 0,
        selected: false,
        price: 0,
    }
};