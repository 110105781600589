import React, {useEffect, useState} from "react";
import {Breadcrumbs, Card, CssBaseline, Link} from "@mui/joy";
import Header from "../dashboard-components/Header";
import SideNav from "../dashboard-components/SideNav";
import Box from "@mui/joy/Box";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import Typography from "@mui/joy/Typography";
import {CssVarsProvider} from "@mui/joy/styles";
import {GetCourseBySlug, GetDocumentByCourseSlug} from "../../axios/Axios";
import {ArrowRightAltSharp} from "@mui/icons-material";
import {useParams} from "react-router-dom";
import AccordionGroup from '@mui/joy/AccordionGroup';
import Accordion from '@mui/joy/Accordion';
import AccordionDetails from '@mui/joy/AccordionDetails';
import AccordionSummary, {accordionSummaryClasses,} from '@mui/joy/AccordionSummary';
import AddIcon from '@mui/icons-material/Add';
import Divider from "@mui/joy/Divider";
import YouTubeEmbed from "../dashboard-components/YouTubeEmbed";

export default function KnowledgeBaseCourseDetail() {
    const {slug} = useParams();
    const [loadingData, setLoadingData] = useState(true);
    const [selectedDocument, setSelectedDocument] = useState<Document>(null);
    const [documents, setDocuments] = useState<Document[]>([]);
    const [course, setCourse] = useState<Course>(null);


    const groupedDocuments = documents.reduce((acc, document) => {
        if (!acc[document.category]) {
            acc[document.category] = [];
        }
        acc[document.category].push(document);
        return acc;
    }, {} as Record<string, Document[]>);

    useEffect(() => {
        setLoadingData(true)

        const getCourse = async () => {
            let course = await GetCourseBySlug(slug);
            setCourse(course)

        };

        const getDocuments = async () => {
            let document = await GetDocumentByCourseSlug(slug);
            if (document.length > 0) {
                setSelectedDocument(document[0])
            }
            setDocuments(document)
        };
        getDocuments();
        getCourse();
        setLoadingData(false)


    }, [])

    return (
        <CssVarsProvider disableTransitionOnChange>
            <CssBaseline/>
            <Box sx={{display: 'flex'}}>
                <Header/>
                <SideNav/>
                <Box
                    component="main"
                    className="MainContent"
                    sx={{
                        px: {xs: 2, md: 6},
                        pt: {
                            xs: 'calc(12px + var(--Header-height))',
                            sm: 'calc(12px + var(--Header-height))',
                            md: 3,
                        },
                        pb: {xs: 2, sm: 2, md: 3},
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth: 0,
                        gap: 1,
                        height: 'fit-content'
                    }}
                >
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <Breadcrumbs
                            size="sm"
                            aria-label="breadcrumbs"
                            separator={<ChevronRightRoundedIcon/>}
                            sx={{pl: 0}}
                        >

                            <HomeRoundedIcon/>
                            <Link
                                underline="hover"
                                color="neutral"
                                href="/dashboard"
                                fontSize={12}
                                fontWeight={500}
                            >
                                Dashboard
                            </Link>
                            <Link
                                underline="hover"
                                color="neutral"
                                href="/dashboard/knowledge-base"
                                fontSize={12}
                                fontWeight={500}
                            >
                                Knowledge Base
                            </Link>
                            <Link
                                underline="hover"
                                color="primary"
                                href={`/dashboard/knowledge-base/${document?.slug}`}
                                fontSize={12}
                                fontWeight={500}
                            >
                                {course?.title}
                            </Link>
                        </Breadcrumbs>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                        }}
                    >
                        <Card sx={{borderRadius: 0, borderRight: 'none'}}>
                            <Typography sx={{textAlign: 'center'}} level={"title-md"}>{course?.title}</Typography>
                            <Divider/>
                            <AccordionGroup
                                sx={{
                                    maxWidth: 400,
                                    [`& .${accordionSummaryClasses.indicator}`]: {
                                        transition: '0.2s',
                                    },
                                    [`& [aria-expanded="true"] .${accordionSummaryClasses.indicator}`]: {
                                        transform: 'rotate(45deg)',
                                    },
                                }}
                            >
                                {Object.keys(groupedDocuments).map((category) => (
                                    <Accordion defaultExpanded={true} key={category}>
                                        <AccordionSummary sx={{textWrap: 'nowrap'}} indicator={<AddIcon/>}>
                                            {category}
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <div>
                                                {groupedDocuments[category].map((document) => (
                                                    <Typography startDecorator={<ArrowRightAltSharp/>}
                                                                sx={{cursor: 'pointer', mb: 2, mt: 2}}
                                                                onClick={() => setSelectedDocument(document)}>{document.title}</Typography>
                                                ))}
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                ))}
                            </AccordionGroup>
                        </Card>
                        <Card sx={{width: '100%', display: 'flex', borderRadius: 0, padding: 5}}>
                            <Box sx={{width: '100%'}}>
                                <Box sx={{width: '100%', mb: 2}}>
                                    <Typography level={"title-lg"}>
                                        {selectedDocument?.title}
                                    </Typography>
                                    <Typography level={"title-md"}>
                                        by {selectedDocument?.author}
                                    </Typography>
                                </Box>
                                <Divider sx={{mb: 2}}/>
                                {selectedDocument?.youtube_link && (
                                    <>
                                        <YouTubeEmbed videoId={selectedDocument?.youtube_link}/>
                                        <Divider sx={{mb: 2, mt: 2}}/>
                                    </>
                                )}
                                <Box sx={{width: '100%', mb: 2}}
                                     dangerouslySetInnerHTML={{__html: selectedDocument?.content}}/>
                            </Box>

                        </Card>
                        <Card sx={{borderRadius: 0, borderLeft: 'none'}}>
                            <Typography sx={{textAlign: 'center', textWrap: 'nowrap'}} level={"title-md"}>Key
                                Documents</Typography>
                            {selectedDocument?.links && selectedDocument?.links.length > 0 ? (
                                <>
                                    {selectedDocument?.links.map((link, index) => (
                                        <Link href={link.link}>
                                            {link.title}
                                        </Link>
                                    ))}
                                </>

                            ) : (
                                <p>No links available.</p>
                            )}
                        </Card>
                    </Box>
                </Box>
            </Box>
        </CssVarsProvider>
    )
}