import {LogoutUser} from "../../axios/Axios";
import {useNavigate} from "react-router-dom";
import React from "react";

export default function Logout() {

    const navigate = useNavigate();
    LogoutUser().finally(() => {
        navigate("/");
    })

    return (<></>)
}