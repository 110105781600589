import * as React from 'react';
import {useEffect, useRef} from 'react';
import {
    Breadcrumbs,
    Card,
    Chip,
    CircularProgress,
    CssBaseline,
    CssVarsProvider,
    Divider,
    Link,
    RadioGroup,
    Select,
    styled,
    SvgIcon,
    Textarea
} from "@mui/joy";
import Radio, {radioClasses} from '@mui/joy/Radio';

import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import SideNav from "../dashboard-components/SideNav";
import Header from "../dashboard-components/Header";
import {EmployeeUpdateBooking, GetBookingsToday} from "../../axios/Bookings-Axios";
import Option from "@mui/joy/Option";
import FormLabel from '@mui/joy/FormLabel';
import Button from "@mui/joy/Button";
import {toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import DatePicker from "react-datepicker";
import {CheckCircleRounded, WarningAmber} from "@mui/icons-material";
import Checkbox from "@mui/joy/Checkbox";
import Sheet from "@mui/joy/Sheet";
import ListItem from "@mui/joy/ListItem";
import List from "@mui/joy/List";

interface DatePickerProps
    extends React.InputHTMLAttributes<HTMLInputElement> {
    onCLick: (date: Date | null, event?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) => void

}

const VisuallyHiddenInput = styled('input')`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
`;

function formatDate(date) {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
}

export default function TodaysBookings() {
    const [loadingData, setLoadingData] = React.useState(false);
    const [bookings, setBookings] = React.useState <Booking[]>([]);
    const [filterDate, setFilterDate] = React.useState<Date>(new Date());
    const hasUserEdited = useRef(false);

    useEffect(() => {
        if (!loadingData && bookings.length > 0 && hasUserEdited.current) {  // Ensure bookings exist
            const saveBooking = async (booking) => {
                try {
                    await EmployeeUpdateBooking(booking?.customer?.notes, booking.payment_type, booking.id, booking.job_status, booking.ask_for_review);
                } catch (error) {
                    console.error('Error updating booking:', error);
                }
            };

            const updateAllBookings = async () => {
                for (const booking of bookings) {
                    await saveBooking(booking);
                }
                toast.success('Saved Booking!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
            };

            updateAllBookings();
            hasUserEdited.current = false;
        }
    }, [bookings]);


    useEffect(() => {
        setLoadingData(true);  // Set loading state before fetching

        const fetchData = async () => {
            try {
                let location = localStorage.getItem('location')?.toLowerCase().replace(' ', '_');
                let bookings = await GetBookingsToday([location], formatDate(filterDate));
                setBookings(bookings);
            } catch (error) {
                console.error('Error fetching bookings:', error);
            } finally {
                setLoadingData(false);
            }
        };

        fetchData();
    }, [filterDate]);


    const handlePaymentTypeChange = (bookingId, value) => {
        hasUserEdited.current = true;

        if (bookingId && value) {
            const updatedBookings = bookings.map(booking =>
                booking.id === bookingId
                    ? {...booking, payment_type: {name: value, id: -1}}
                    : booking
            );
            setBookings(updatedBookings);
        }
    };

    const handleAskForReviewChange = (bookingId, event) => {
        hasUserEdited.current = true;

        let value = event.target.checked
        if (bookingId) {
            const updatedBookings = bookings.map(booking =>
                booking.id === bookingId
                    ? {...booking, ask_for_review: value}
                    : booking
            );
            setBookings(updatedBookings);
        }

    };

    const handleNotesChange = (bookingId: number) => (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        hasUserEdited.current = true;

        const {value} = event.target;
        const updatedBookings = bookings.map(booking =>
            booking.id === bookingId
                ? {
                    ...booking,
                    customer: booking.customer
                        ? {
                            ...booking.customer,
                            notes: value
                        }
                        : undefined
                }
                : booking
        );
        setBookings(updatedBookings);
    };


    const handleJobStatusChange = (bookingId, status) => {
        hasUserEdited.current = true;

        if (bookingId && status) {
            const updatedBookings = bookings.map(booking =>
                booking.id === bookingId
                    ? {...booking, job_status: status}
                    : booking
            );
            setBookings(updatedBookings);
        }

    };


    return (
        <CssVarsProvider disableTransitionOnChange>
            <CssBaseline/>
            <ToastContainer/>
            <Box sx={{display: 'flex', minHeight: '100dvh'}}>
                <Header/>
                <SideNav/>
                <Box
                    component="main"
                    className="MainContent"
                    sx={{
                        px: {xs: 2, md: 6},
                        pt: {
                            xs: 'calc(12px + var(--Header-height))',
                            sm: 'calc(12px + var(--Header-height))',
                            md: 3,
                        },
                        pb: {xs: 2, sm: 2, md: 3},
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth: 0,
                        height: '100dvh',
                        gap: 1,
                    }}
                >
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <Breadcrumbs
                            size="sm"
                            aria-label="breadcrumbs"
                            separator={<ChevronRightRoundedIcon/>}
                            sx={{pl: 0}}
                        >
                            <Link
                                underline="none"
                                color="neutral"
                                href="/dashboard"
                                aria-label="Home"
                            >
                                <HomeRoundedIcon/>
                            </Link>
                            <Link
                                underline="hover"
                                color="neutral"
                                href="/dashboard"
                                fontSize={12}
                                fontWeight={500}
                            >
                                Dashboard
                            </Link>
                            <Typography color="primary" fontWeight={500} fontSize={12}>
                                Todays Bookings
                            </Typography>
                        </Breadcrumbs>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            mb: 1,
                            gap: 1,
                            flexDirection: {xs: 'column', sm: 'row'},
                            alignItems: {xs: 'start', sm: 'center'},
                            flexWrap: 'wrap',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Typography level="h2" component="h1">
                            Todays Bookings
                        </Typography>
                    </Box>
                    <Box sx={{width: 'fit-content'}}>
                        <FormLabel>Pick a date</FormLabel>
                        <DatePicker
                            selected={filterDate}
                            dateFormat="dd/MM/yyyy"
                            onChange={(date) => {
                                setFilterDate(date);
                            }}
                        />
                    </Box>


                    {loadingData ? (
                        <Box sx={{
                            display: 'flex',
                            gap: 2,
                            alignItems: 'center',
                            flexWrap: 'wrap',
                            minWidth: '50%',
                            height: '100%',
                            justifyContent: 'center'
                        }}>
                            <CircularProgress
                                size="lg"
                            />
                        </Box>
                    ) : (
                        <Box>
                            {bookings.length === 0 ? (
                                <Typography startDecorator={<WarningAmber/>} level={"title-sm"}
                                            sx={{marginTop: '10px'}}>
                                    No bookings on {formatDate(filterDate)}
                                </Typography>
                            ) : (
                                <Box sx={{display: 'flex', flexWrap: 'wrap', gap: '16px', marginBottom: '100px'}}>
                                    {bookings.map((booking, index) => (

                                        <Card key={index}
                                              sx={{flex: '1 1 calc(50% - 16px)', justifyContent: 'space-between'}}>

                                            <Box>
                                                <FormLabel>Customer Information</FormLabel>
                                                <Typography level={"title-md"}>
                                                    Name: <Typography
                                                    fontWeight={350}>{booking?.customer?.full_name}</Typography>
                                                </Typography>
                                                <Typography sx={{marginBottom: '0px'}} level={"title-md"}>
                                                    Street Address: <Typography
                                                    fontWeight={350}>{booking?.customer?.street_address}</Typography>
                                                </Typography>
                                                <Typography sx={{marginTop: '0px'}} level={"title-md"}>
                                                    Postcode: <Typography
                                                    fontWeight={350}>{booking?.customer?.postcode}</Typography>
                                                </Typography>
                                                <Typography sx={{marginTop: '0px'}} level={"title-md"}>
                                                    Number: <Typography
                                                    fontWeight={350}>{booking?.customer?.phone}</Typography>
                                                </Typography>
                                                <Typography sx={{marginTop: '0px'}} level={"title-md"}>
                                                    Email: <Typography
                                                    fontWeight={350}>{booking?.customer?.email}</Typography>
                                                </Typography>
                                                <Box sx={{display: 'flex'}}>
                                                    <Typography sx={{marginTop: '0px', marginRight: '5px'}}
                                                                level={"title-md"}>
                                                        Invoice Sent:
                                                    </Typography>

                                                    {booking?.invoice_id ? (
                                                        <Chip color={"success"}>Invoice ID:
                                                            #{booking?.invoice_id}</Chip>
                                                    ) : (
                                                        <Chip color={"danger"}>No</Chip>
                                                    )}
                                                </Box>

                                            </Box>
                                            <Box>
                                                <FormLabel>Notes</FormLabel>
                                                <Textarea
                                                    name="notes"
                                                    value={booking?.customer?.notes || ''}
                                                    onChange={handleNotesChange(booking.id)}
                                                    sx={{background: 'white'}}
                                                    variant="outlined"
                                                    minRows={4}
                                                    maxRows={4}
                                                />
                                            </Box>
                                            <Divider/>

                                            <FormLabel>Emails / Text Confirmations (Only sent on 'Complete')</FormLabel>
                                            <Checkbox
                                                defaultChecked={booking.ask_for_review}
                                                color="primary" variant="soft"
                                                label="Ask Customer For Review"
                                                onChange={(event) => {
                                                    handleAskForReviewChange(booking.id, event);
                                                }}
                                            />
                                            <Checkbox
                                                defaultChecked
                                                disabled
                                                color="primary" variant="soft"
                                                label="Send Gutter Certificate Email"
                                            />
                                            <Checkbox
                                                defaultChecked
                                                disabled
                                                color="primary" variant="soft"
                                                label="Send Confirmation Text"
                                            />
                                            <Divider/>

                                            <Box>
                                                <FormLabel>Before / After Images</FormLabel>

                                                <List>
                                                    <ListItem sx={{}}>
                                                        <Sheet variant={"outlined"} sx={{
                                                            background: 'white',
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            width: '100%',
                                                            padding: '15px'
                                                        }}>
                                                            <Typography>Before / After #1</Typography>
                                                            <Button
                                                                component="label"
                                                                role={undefined}
                                                                tabIndex={-1}
                                                                variant="outlined"
                                                                sx={{width: '100%', borderRadius: 0, p: 2, mb: 1}}
                                                                startDecorator={
                                                                    <SvgIcon>
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            fill="none"
                                                                            viewBox="0 0 24 24"
                                                                            strokeWidth={1.5}
                                                                            stroke="currentColor"
                                                                        >
                                                                            <path
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                                d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
                                                                            />
                                                                        </svg>
                                                                    </SvgIcon>
                                                                }
                                                            >
                                                                Upload before
                                                                <VisuallyHiddenInput type="file"/>
                                                            </Button>

                                                            <Button
                                                                component="label"
                                                                role={undefined}
                                                                tabIndex={-1}
                                                                variant="outlined"
                                                                sx={{width: '100%', borderRadius: 0, p: 2}}
                                                                startDecorator={
                                                                    <SvgIcon>
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            fill="none"
                                                                            viewBox="0 0 24 24"
                                                                            strokeWidth={1.5}
                                                                            stroke="currentColor"
                                                                        >
                                                                            <path
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                                d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
                                                                            />
                                                                        </svg>
                                                                    </SvgIcon>
                                                                }
                                                            >
                                                                Upload after
                                                                <VisuallyHiddenInput type="file"/>
                                                            </Button>
                                                        </Sheet>

                                                    </ListItem>

                                                </List>


                                            </Box>
                                            <Divider/>


                                            <Box>
                                                <FormLabel>Payment Type</FormLabel>
                                                <Select
                                                    placeholder="Click here to select a payment type…"
                                                    value={booking.payment_type?.name || ''}
                                                    onChange={(e, newValue) => {
                                                        handlePaymentTypeChange(booking.id, newValue);
                                                    }}
                                                    sx={{background: 'white'}}
                                                >
                                                    <Option value="Cash">Cash</Option>
                                                    <Option value="Card">Card</Option>
                                                    <Option value="Invoice">Invoice</Option>
                                                </Select>
                                            </Box>
                                            <Divider/>
                                            <Box>
                                                <FormLabel sx={{marginTop: '10px'}}>Change Status</FormLabel>

                                                <RadioGroup
                                                    aria-label="Change Status"
                                                    overlay
                                                    defaultValue={booking?.job_status}
                                                    name="status"
                                                    sx={{

                                                        flexDirection: 'row',
                                                        gap: 2,
                                                        [`& .${radioClasses.checked}`]: {
                                                            [`& .${radioClasses.action}`]: {
                                                                inset: -1,
                                                                border: '3px solid',
                                                                borderColor: 'primary.500',
                                                            },
                                                        },
                                                        [`& .${radioClasses.radio}`]: {
                                                            display: 'contents',
                                                            '& > svg': {
                                                                zIndex: 2,
                                                                position: 'absolute',
                                                                top: '-8px',
                                                                right: '-8px',
                                                                bgcolor: 'background.surface',
                                                                borderRadius: '50%',
                                                            },
                                                        },
                                                    }}
                                                >
                                                    {['Mark As Complete', 'Mark As Needs Rescheduling', 'Mark As Cancelled'].filter(
                                                        (value) => value !== 'Mark As Complete' || booking?.payment_type !== null
                                                    ).map((value) => (
                                                        <Sheet
                                                            key={value}
                                                            onClick={() => handleJobStatusChange(booking?.id, value)}
                                                            variant="outlined"
                                                            sx={{
                                                                background: 'white',
                                                                borderRadius: 'md',
                                                                width: '100%',
                                                                boxShadow: 'sm',
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                                alignItems: 'center',
                                                                textAlign: 'center',
                                                                gap: 1.5,
                                                                p: 2,
                                                                minWidth: 120,
                                                            }}
                                                        >
                                                            <FormLabel sx={{marginBottom: '-10px'}} htmlFor={value}>
                                                                {value}
                                                            </FormLabel>
                                                            <Radio id={value} value={value}
                                                                   checkedIcon={<CheckCircleRounded/>}/>
                                                            <Typography level="body-xs" color="neutral">
                                                                (Click Here)
                                                            </Typography>
                                                        </Sheet>
                                                    ))}

                                                </RadioGroup>


                                            </Box>

                                        </Card>
                                    ))}
                                </Box>
                            )}
                        </Box>
                    )}

                </Box>
            </Box>
        </CssVarsProvider>
    )

}