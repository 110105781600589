import React, {useEffect, useState} from "react";
import {
    CircularProgress,
    Dropdown,
    ListItemDecorator,
    Menu,
    MenuButton,
    MenuItem,
    Modal,
    ModalClose,
    ModalDialog,
    Radio,
    RadioGroup
} from "@mui/joy";
import Box from "@mui/joy/Box";
import {CreateLead, DeleteLeadById, GetAllServicePrices, GetLeadById, UpdateLead} from "../../axios/Bookings-Axios";
import Input from "@mui/joy/Input";
import Typography from "@mui/joy/Typography";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import {ArrowDropDown, CleanHands, CleaningServices, DeleteForever, Roofing, SolarPower} from "@mui/icons-material";
import 'react-datepicker/dist/react-datepicker.css';
import Button from "@mui/joy/Button";
import {GetUser} from "../../axios/Axios";
import BookingsModal from "./BookingsModal";
import ConfirmDeleteModal from "./ConfirmDeleteModal";
import CustomersTopComponent from "./CustomersTopComponent";
import {INITIAL_SERVICES} from "../../domain/Services";

interface User {
    username: string;
}

interface Service {
    name: string;
    label: string;
    icon: React.ReactNode;
    defaultPrice: number;
    selected: boolean;
    price: number;
}

interface ServiceState {
    [key: string]: Service;
}

function formatDate(old_date) {
    let date = new Date(old_date);
    if (!isNaN(date.getTime())) {
        return date.toLocaleString('en-US', {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric'
        });
    } else {
        return 'No Valid Date';
    }
}

export default function LeadsModal({openState, leadId, parentCallback}) {
    const [open, setOpen] = React.useState(openState);
    const [loadedData, setLoadedData] = React.useState(false);
    const [user, setUser] = useState<User>({username: ''});
    const [openBookingModal, setOpenBookingModal] = React.useState(false);
    const [openDeleteModal, setOpenDeleteModal] = React.useState<boolean>(false);

    const [customer, setCustomer] = React.useState<Customer>({
        advertisement_platform: undefined,
        created_at: "",
        email: "",
        full_name: "",
        house_type: {name: 'Detached', id: 1},
        id: 0,
        notes: "",
        phone: "",
        postcode: "",
        street_address: ""
    });

    const [lead, setLead] = React.useState<Lead>({
        created_at: "",
        customer: customer,
        id: -1,
        notes: undefined,
        paid: false,
        services: [],
        service_price: [],
        status: []
    });

    const [services, setServices] = React.useState<ServiceState>(INITIAL_SERVICES);


    const totalPrice = Object.values(services).reduce(
        (sum, service) => sum + (service.selected ? service.price : 0),
        0
    );

    useEffect(() => {
        const fetchData = async () => {
            const userResponse = await GetUser();
            setUser(userResponse.data);
        };
        fetchData()
    }, []);

    useEffect(() => {
        setLead(prevLead => ({
            ...(prevLead || {}),
            customer: customer
        }));
    }, [customer]);

    function setStatus(status) {
        let prevStatus = lead.status;
        prevStatus.push({id: -1, status: status, created_at: new Date().toISOString(), user: user.username})
        setLead(prevLead => ({
            ...prevLead,
            status: prevStatus,
        }));
    }

    function convertToBooking() {
        const updateLead = async () => {
            if (lead.id !== -1) {
                let createdLead = await UpdateLead(leadId, lead, services);
            } else {
                let createdLead = await CreateLead(lead, services);
            }
        }
        updateLead()
        setOpen(false)
        setOpenBookingModal(true)
    }

    useEffect(() => {
        if (leadId == 0) {
            return;
        }
        setLoadedData(false);
        const fetchData = async () => {
            const servicePriceTier = await GetAllServicePrices('D', 'Detached');
            if (leadId == -1) {
                const defaultPrices = {
                    gutterClean: servicePriceTier[0]?.gutter_clean_price || 0,
                    conservatoryGutterClean: servicePriceTier[0]?.conservatory_gutter_clean_price || 0,
                    conservatoryWindowsRoofAndUPVCDoors: servicePriceTier[0]?.conservatory_windows_roof_and_upvc_price  || 0,
                    faciasSoffitsAndOutsideGutters: servicePriceTier[0]?.facias_soffits_outside_gutters_price  || 0,
                    windowSillsAndUPVCDoors: servicePriceTier[0]?.windows_sills_upvc_price  || 0,
                };

                setServices(prev => {
                    const updated = {...prev};
                    for (const key in updated) {
                        updated[key] = {
                            ...updated[key],
                            defaultPrice: defaultPrices[key] || 0,
                            price: defaultPrices[key] || 0
                        };
                    }
                    return updated;
                });

            } else {
                let leads = await GetLeadById(leadId);
                if (leads['customer']['notes'] == null) {
                    leads['customer']['notes'] = ''
                }
                setCustomer(leads['customer']);
                setLead(leads);

                const franchisePrices = leads?.customer?.franchise_postcode?.service_price_tier;
                const defaultPrices = {
                    gutterClean: franchisePrices?.gutter_clean_price ?? servicePriceTier[0]?.gutter_clean_price ?? 0,
                    conservatoryWindowsRoofAndUPVCDoors: franchisePrices?.conservatory_windows_roof_and_upvc_price ?? servicePriceTier[0].conservatory_windows_roof_and_upvc_price ?? 0,
                    faciasSoffitsAndOutsideGutters: franchisePrices?.facias_soffits_outside_gutters_price ?? servicePriceTier[0].facias_soffits_outside_gutters_price ?? 0,
                    windowSillsAndUPVCDoors: franchisePrices?.windows_sills_upvc_price ?? servicePriceTier[0].windows_sills_upvc_price ?? 0,
                    conservatoryGutterClean: franchisePrices?.conservatory_gutter_clean_price ??
                        servicePriceTier[0].conservatory_gutter_clean_price ?? 0
                };


                const serviceState = {...services};
                for (const key in serviceState) {
                    const serviceName = serviceState[key].name;
                    serviceState[key] = {
                        ...serviceState[key],
                        selected: leads.services.some(service => service.name === serviceName),
                        price: leads.service_price?.find(item => item.service.name === serviceName)?.price ?? defaultPrices[key] ?? 0,
                        defaultPrice: defaultPrices[key] ?? 0
                    };
                }

                setServices(serviceState);
            }

            setLoadedData(true);
        };
        fetchData();
    }, []);

    function closeModal() {
        setOpen(false)
        parentCallback();
    }

    const handleSave = async (event) => {
        event.preventDefault()
        const updateSavingLead = async () => {
            if (lead.id !== -1) {
                let updatedLead = await UpdateLead(leadId, lead, services);
                return updatedLead
            } else {
                let createdLead = await CreateLead(lead, services);
                return createdLead
            }
        };

        await updateSavingLead();
        window.location.reload(false);
    };

    const handleServiceToggle = (serviceKey: string) => {
        setServices(prev => ({
            ...prev,
            [serviceKey]: {
                ...prev[serviceKey],
                selected: !prev[serviceKey].selected,
                price: !prev[serviceKey].selected ? prev[serviceKey].defaultPrice : 0
            }
        }));
    };

    const handlePriceChange = (serviceKey: string, value: string) => {
        const price = parseFloat(value) || 0;
        setServices(prev => ({
            ...prev,
            [serviceKey]: {
                ...prev[serviceKey],
                price: price
            }
        }));
    };

    const renderServiceItem = (serviceKey: string) => {
        const service = services[serviceKey];
        return (
            <ListItem variant="outlined" key={service.name} sx={{boxShadow: 'sm'}}>
                <ListItemDecorator>
                    {service.icon}
                </ListItemDecorator>
                <RadioGroup sx={{width: '100%'}}>
                    <Radio
                        overlay
                        checked={service.selected}
                        value={service.name}
                        label={service.label}
                        onClick={() => handleServiceToggle(serviceKey)}
                        sx={{flexGrow: 1, flexDirection: 'row-reverse'}}
                        slotProps={{
                            action: ({checked}) => ({
                                sx: (theme) => ({
                                    ...(checked && {
                                        inset: -1,
                                        border: '2px solid',
                                        borderColor: theme.vars.palette.primary[500],
                                    }),
                                }),
                            }),
                        }}
                    />
                </RadioGroup>
            </ListItem>
        );
    };

    const renderPriceInput = (serviceKey: string) => {
        const service = services[serviceKey];
        return (
            <ListItem sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                <Typography>
                    {service.label} Price
                </Typography>
                <Input
                    startDecorator={'£'}
                    sx={{maxWidth: '200px'}}
                    type="number"
                    value={service.selected ? service.price.toString() : '0'}
                    onChange={(e) => handlePriceChange(serviceKey, e.target.value)}
                    disabled={!service.selected}
                />
            </ListItem>
        );
    };

    let handleCallback = () => {
        setOpenBookingModal(false)
    };

    let handleDeleteCallBack = async (doDelete) => {
        setOpenDeleteModal(false)
        if (!doDelete) {
            return;
        }
        const deleteLead = async () => {
            return await DeleteLeadById(leadId);
        }
        await deleteLead();
        window.location.reload(false);
    };

    return (
        <>
            <Modal slotProps={{
                backdrop: {
                    sx: {
                        backdropFilter: 'none',
                        backgroundColor: 'rgb(108 108 108 / 60%)',
                        padding: '10px'
                    },
                },
            }} open={open} onClose={() => closeModal()}>
                <ModalDialog sx={{overflowY: 'scroll'}}>
                    <form onSubmit={handleSave} id="a-form">
                        <ModalClose/>
                        <Box sx={{
                            minWidth: 600,
                            display: 'flex',
                            flexDirection: 'column',
                            overflowX: 'hidden',
                        }}>
                        </Box>

                        {!loadedData ?
                            <Box sx={{
                                display: 'flex',
                                gap: 2,
                                alignItems: 'center',
                                flexWrap: 'wrap',
                                minWidth: '50%',
                                height: '100%',
                                justifyContent: 'center'
                            }}>
                                <CircularProgress size="lg"/>
                            </Box>
                            :
                            <>
                                <Box>
                                    <CustomersTopComponent customer={customer} setCustomer={setCustomer}/>
                                    <Box sx={{marginTop: '10px'}}>
                                        <Typography variant="soft" sx={{marginBottom: '5px', width: '100%'}}>
                                            Past Updates
                                        </Typography>
                                        <Box sx={{
                                            border: '1px solid #CDD7E1',
                                            borderRadius: '6px',
                                            maxHeight: '150px',
                                            overflowY: 'scroll',
                                            padding: '10px'
                                        }}>
                                            {lead.status.length > 0 ? (
                                                lead.status.map((leadStatus) => (
                                                    <div style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        justifyContent: 'space-between',
                                                        padding: '5px',
                                                        marginBottom: '5px'
                                                    }}>
                                                        <Typography level="body-md">
                                                            {formatDate(leadStatus.created_at)}
                                                        </Typography>
                                                        <Typography level="body-md">-</Typography>
                                                        <Typography level="body-md">{leadStatus.status}</Typography>
                                                        <Typography level="body-md">-</Typography>
                                                        <Typography level="body-md">{leadStatus.user}</Typography>
                                                    </div>
                                                ))
                                            ) : (
                                                <Typography>No Status Updates Found</Typography>
                                            )}
                                        </Box>
                                    </Box>
                                    <Box sx={{marginTop: '10px'}}>
                                        <Typography variant="soft" sx={{marginBottom: '5px', width: '100%'}}>
                                            Selected Services
                                        </Typography>
                                        <Box sx={{display: "flex"}}>
                                            <Box sx={{
                                                minHeight: '100%',
                                                display: "flex",
                                                flexDirection: "row",
                                                minWidth: '100%',
                                            }}>
                                                <Box sx={{minWidth: '50%', marginRight: "15px"}}>
                                                    <List
                                                        sx={{
                                                            '--List-gap': '0.5rem',
                                                            '--ListItem-paddingY': '1rem',
                                                            '--ListItem-radius': '8px',
                                                            '--ListItemDecorator-size': '32px',
                                                        }}
                                                    >
                                                        {Object.keys(services).map(serviceKey =>
                                                            renderServiceItem(serviceKey)
                                                        )}
                                                    </List>
                                                </Box>
                                                <Box sx={{
                                                    minWidth: '50%',
                                                    marginBottom: "5px",
                                                }}>
                                                    <Box>
                                                        {Object.keys(services).map(serviceKey =>
                                                            renderPriceInput(serviceKey)
                                                        )}
                                                    </Box>
                                                    <Typography level="title-md">
                                                        Total Price: £{totalPrice}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>

                                <Box sx={{display: 'flex', justifyContent: 'flex-end', width: '100%'}}>
                                    {leadId !== -1 && (
                                        <Button
                                            color="danger"
                                            endDecorator={<DeleteForever/>}
                                            onClick={() => setOpenDeleteModal(true)}
                                        >
                                            Delete
                                        </Button>
                                    )}
                                    <Dropdown>
                                        <MenuButton color="primary" sx={{marginRight: '10px', marginLeft: '10px'}}
                                                    endDecorator={<ArrowDropDown/>}>
                                            Change Lead Status
                                        </MenuButton>
                                        <Menu sx={{zIndex: 7700}}>
                                            <MenuItem onClick={(event) => setStatus('Opt Out')} sx={{zIndex: 7700}}>
                                                Opt Out
                                            </MenuItem>
                                            <MenuItem onClick={(event) => setStatus('No Answer')} sx={{zIndex: 7700}}>
                                                No Answer
                                            </MenuItem>
                                            <MenuItem onClick={(event) => setStatus('Call Back')} sx={{zIndex: 7700}}>
                                                Call Back
                                            </MenuItem>
                                        </Menu>
                                    </Dropdown>

                                    {leadId !== -1 && (
                                        <Button color="success" type="submit">
                                            Save Lead
                                        </Button>
                                    )}
                                    {leadId === -1 && (
                                        <Button sx={{marginLeft: '10px'}} color="success" type="submit">
                                            Create New Lead
                                        </Button>
                                    )}
                                    <Button
                                        onClick={() => convertToBooking()}
                                        sx={{marginLeft: '10px'}}
                                    >
                                        Convert To Booking
                                    </Button>
                                </Box>
                            </>
                        }
                    </form>
                </ModalDialog>
            </Modal>
            <React.Fragment>
                <ConfirmDeleteModal
                    discardMessage={"Are you sure you want to delete this booking?"}
                    openState={openDeleteModal}
                    parentCallback={handleDeleteCallBack}
                />
                <BookingsModal
                    key={'bookings-modal-' + openBookingModal}
                    openState={openBookingModal}
                    newBooking={lead}
                    newBookingValue={services}
                    bookingId={-1}
                    parentCallback={handleCallback}
                />
            </React.Fragment>
        </>
    )
}