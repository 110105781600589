import * as React from 'react';
import {useEffect} from 'react';
import IconButton from '@mui/joy/IconButton';
import Table from '@mui/joy/Table';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {GetAllBookings} from "../../axios/Bookings-Axios";
import Button from "@mui/joy/Button";
import Box from "@mui/joy/Box";
import {Close, Done, KeyboardArrowLeft, KeyboardArrowRight, Message, PhoneCallback} from "@mui/icons-material";
import {CircularProgress} from "@mui/joy";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import {useSearchParams} from "react-router-dom";
import BookingsModal from "../modals/BookingsModal";
import DebounceInput from "../../domain/DebounceInput";
import {CallCustomerById} from "../../axios/Axios";

interface Row {
    id: string,
    booked_in: string,
    created_at: string,
    is_recurring: boolean,
    gutter_clean: boolean,
    conservatory_gutter_clean: boolean,
    roof_clean: boolean,
    status: string,
    expanded_row: RowExpanded
}

interface RowExpanded {
    full_name: string,
    street_address: string,
    postcode: string,
    phone: string,
    notes: string,
    house_type: string,
    id: string,
    email: string,
}

function formatDate(old_date) {
    let date = new Date(old_date);

    if (!isNaN(date.getTime())) {
        return date.toLocaleString('en-US', {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric'
        });
    } else {
        return 'No Valid Date';
    }
}

function Row(props: { row: Row; initialOpen?: boolean, onRowClick: (rowId: number) => void }) {
    const {row} = props;
    const [open, setOpen] = React.useState(props.initialOpen || false);

    const handleClick = () => {
        props.onRowClick(Number(row.id));
    };
    const handleCallCustomer = () => {
        let location = localStorage.getItem('location')?.toLowerCase()
        CallCustomerById(row.id, location)
    };

    const handleMessageCustomer = () => {

    };

    return (
        <React.Fragment>
            <tr style={{width: '100%'}}>
                <td>
                    <IconButton
                        aria-label="expand row"
                        variant="plain"
                        color="neutral"
                        size="sm"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                    </IconButton>
                </td>
                <td>{formatDate(row.created_at)}</td>
                <td>{formatDate(row.booked_in)}</td>
                <td>{row.expanded_row.full_name}</td>
                <td>{!row.roof_clean ? <Close color="action"/> : <Done color="success"/>}</td>
                <td>{!row.gutter_clean ? <Close color="action"/> : <Done color="success"/>}</td>
                <td>{!row.conservatory_gutter_clean ? <Close color="action"/> : <Done color="success"/>}</td>
                <td>
                    <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                        <Button sx={{textWrap: 'nowrap'}} size="sm" color="primary" onClick={handleClick}>
                            Edit Booking
                        </Button>
                        <IconButton variant="solid" sx={{ml: 1, textWrap: 'nowrap'}} size="sm" color="primary"
                                    onClick={handleCallCustomer}>
                            <PhoneCallback/>
                        </IconButton>
                        <IconButton variant="solid" sx={{ml: 1, textWrap: 'nowrap'}} size="sm" color="primary"
                                    onClick={handleMessageCustomer}>
                            <Message/>
                        </IconButton>
                    </Box>
                </td>

            </tr>
            <tr>
                <td style={{height: 0, padding: 0, width: '100%'}} colSpan={8}>
                    {open && (
                        <Sheet
                            variant="soft"
                            sx={{p: 1, pl: 6, boxShadow: 'inset 0 3px 6px 0 rgba(0 0 0 / 0.08)'}}
                        >
                            <Typography level="body-lg" component="div">
                                Customer Information
                            </Typography>
                            <Table
                                borderAxis="bothBetween"
                                aria-label="purchases"
                                sx={{
                                    '& > thead > tr > th:nth-child(n + 3), & > tbody > tr > td:nth-child(n + 3)':
                                        {textAlign: 'right'},
                                    '--TableCell-paddingX': '0.5rem',
                                }}
                            >
                                <thead>
                                <tr>
                                    <th>Full Name</th>
                                    <th>Number</th>
                                    <th>Email</th>
                                    <th>Street Address</th>
                                    <th>Postcode</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr key={row.id}>
                                    <td>{row.expanded_row.full_name}</td>
                                    <td>{row.expanded_row.phone}</td>
                                    <td>{row.expanded_row.email}</td>
                                    <td>{row.expanded_row.street_address}</td>
                                    <td>{row.expanded_row.postcode}</td>
                                </tr>
                                </tbody>
                            </Table>
                        </Sheet>
                    )}
                </td>
            </tr>
            <tr>
                <td style={{height: 0, padding: 0, width: '100%'}} colSpan={8}>
                    {open && (
                        <Sheet
                            variant="soft"
                            sx={{p: 1, pl: 6, boxShadow: 'inset 0 3px 6px 0 rgba(0 0 0 / 0.08)'}}
                        >
                            <Table
                                borderAxis="bothBetween"
                                aria-label="purchases"
                                sx={{
                                    '& > thead > tr > th:nth-child(n + 3), & > tbody > tr > td:nth-child(n + 3)':
                                        {textAlign: 'right'},
                                    '--TableCell-paddingX': '0.5rem',
                                }}
                            >
                                <thead>
                                <tr>
                                    <th>Notes</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td style={{whiteSpace: 'pre'}}>{row.expanded_row.notes !== '' ? row.expanded_row.notes : 'No notes'}</td>
                                </tr>
                                </tbody>
                            </Table>
                        </Sheet>
                    )}
                </td>
            </tr>

        </React.Fragment>
    );
}

const mapRawDataToRow = (data: any[]): Row[] => {
    return data.map(item => {
        const serviceNames = item.services.map(service => service.name.toLowerCase());
        const gutterClean = serviceNames.includes('gutter clean');
        const conservatoryGutterClean = serviceNames.includes('conservatory gutter clean');
        const roofClean = serviceNames.includes('roof clean');

        return {
            id: item.id.toString(),
            booked_in: item.booked_in,
            created_at: item.created_at,
            status: item.status,
            is_recurring: item.is_recurring,
            gutter_clean: gutterClean,
            conservatory_gutter_clean: conservatoryGutterClean,
            roof_clean: roofClean,
            expanded_row: {
                full_name: item.customer.full_name,
                street_address: item.customer.street_address,
                postcode: item.customer.postcode,
                phone: item.customer.phone,
                notes: item.customer.notes,
                house_type: item.customer.house_type || '',
                id: item.customer.id.toString(),
                email: item.customer.email,
            },
        };
    });
};

function labelDisplayedRows({
                                from,
                                to,
                                count,
                            }: {
    from: number;
    to: number;
    count: number;
}) {
    return `${from}–${to} of ${count !== -1 ? count : `more than ${to}`}`;
}

export default function BookingsTable() {
    const [loadedData, setLoadingData] = React.useState(false);
    const [rows, setRows] = React.useState <Row[]>([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(15);
    const [pageCount, setPageCount] = React.useState(0);
    const [search, setSearch] = React.useState('');
    const [urlSearchParam, setUrlSearchParam] = useSearchParams();

    const [openBookingId, setOpenBookingId] = React.useState(0);
    const [openBookingModal, setOpenBookingModal] = React.useState(false);

    const [openNewBookingId, setOpenNewBookingId] = React.useState(-1);


    let handleCallback = () => {
        setOpenBookingModal(false)
    };

    const handleOpenBookingModal = (rowId) => {
        setOpenNewBookingId(-1)
        setOpenBookingModal(true)
        setOpenBookingId(rowId)
    };

    const handleNewOpenBookingModal = () => {
        handleOpenBookingModal(-1)
        setOpenNewBookingId(-1)
    };

    const getLabelDisplayedRowsTo = () => {
        if (pageCount === -1) {
            return (page + 1) * rowsPerPage;
        }
        return rowsPerPage === -1
            ? pageCount
            : Math.min(pageCount, (page + 1) * rowsPerPage);
    };

    const handleChangePage = (newPage: number) => {
        setPage(newPage);
    };

    useEffect(() => {
        if (!loadedData) {
            return
        }
        setLoadingData(false)
        const fetchData = async () => {
            let location = localStorage.getItem('location')?.toLowerCase().replace(' ', '_')
            let bookings = await GetAllBookings([location], page + 1, search);
            setRows(mapRawDataToRow(bookings['results']))
            setPageCount(bookings['count'])

            setLoadingData(true)
        };
        fetchData()
    }, [page, search]);

    useEffect(() => {
        setLoadingData(false)

        const fetchData = async () => {
            let location = localStorage.getItem('location')?.toLowerCase().replace(' ', '_')
            let airTableBookings = await GetAllBookings([location], page + 1, search);
            setRows(mapRawDataToRow(airTableBookings['results']))
            setPageCount(airTableBookings['count'])

            setLoadingData(true)
        };
        fetchData()
    }, []);

    const handleDebounce = (value: string) => {
        setSearch(value);
    };


    return (
        <>
            <Box style={{display: 'flex', justifyContent: 'space-between'}}>
                <DebounceInput sx={{width: '15%'}} startDecorator={<SearchRoundedIcon/>}
                               placeholder="Search by any field..."
                               debounceTimeout={1000}
                               defaultValue={urlSearchParam.get("lead-id") as string}
                               handleDebounce={handleDebounce}
                />

                <Button onClick={() => {
                    handleNewOpenBookingModal()
                }} sx={{marginLeft: '10px'}}>Create New
                    Booking</Button>
            </Box>
            <Sheet>

                <Table
                    hoverRow
                    aria-label="collapsible table"
                    sx={{
                        height: '100%',
                        width: '100%',
                        '& > thead > tr > th:nth-child(n + 3), & > tbody > tr > td:nth-child(n + 3)':
                            {textAlign: 'right'},
                        '& > tbody > tr:nth-child(odd) > td, & > tbody > tr:nth-child(odd) > th[scope="row"]':
                            {
                                borderBottom: 0,
                            },
                    }}
                >
                    <thead>
                    <tr style={{width: '100%'}}>
                        <th style={{width: 40}} aria-label="empty"/>
                        <th style={{width: '15%'}}>Created At</th>
                        <th style={{width: '15%'}}>Time Booked In</th>
                        <th style={{width: '10%'}}>Customer Name</th>
                        <th style={{width: '150px'}}>Roof Clean</th>
                        <th style={{width: '150px'}}>Gutter Clean</th>
                        <th style={{width: '200px'}}>Conservatory Gutter Clean</th>
                        <th aria-label="last"></th>
                    </tr>
                    </thead>
                    {loadedData ?
                        <>
                            <tbody>
                            {rows.map((row, index) => (
                                <Row key={row.id} row={row} initialOpen={search.length !== 0}
                                     onRowClick={handleOpenBookingModal}/>
                            ))}
                            </tbody>
                            <tfoot>
                            <tr>
                                <td colSpan={8}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: 2,
                                            justifyContent: 'flex-end',
                                        }}
                                    >
                                        <Typography textAlign="center" sx={{minWidth: 80}}>
                                            {labelDisplayedRows({
                                                from: pageCount === 0 ? 0 : page * rowsPerPage + 1,
                                                to: getLabelDisplayedRowsTo(),
                                                count: pageCount === -1 ? -1 : pageCount,
                                            })}
                                        </Typography>
                                        <Box sx={{display: 'flex', gap: 1}}>
                                            <IconButton
                                                size="sm"
                                                color="neutral"
                                                variant="outlined"
                                                disabled={page === 0}
                                                onClick={() => handleChangePage(page - 1)}
                                                sx={{bgcolor: 'background.surface'}}
                                            >
                                                <KeyboardArrowLeft/>
                                            </IconButton>
                                            <IconButton
                                                size="sm"
                                                color="neutral"
                                                variant="outlined"
                                                disabled={
                                                    pageCount !== -1
                                                        ? page >= Math.ceil(pageCount / rowsPerPage) - 1
                                                        : false
                                                }
                                                onClick={() => handleChangePage(page + 1)}
                                                sx={{bgcolor: 'background.surface'}}
                                            >
                                                <KeyboardArrowRight/>
                                            </IconButton>
                                        </Box>
                                    </Box>
                                </td>
                            </tr>
                            </tfoot>
                        </>
                        :
                        <tbody>
                        <tr style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                            position: 'absolute',
                            marginTop: '50px',
                        }}>
                            <CircularProgress
                                size="lg"
                            />
                        </tr>
                        </tbody>
                    }
                </Table>

            </Sheet>
            <React.Fragment>
                <BookingsModal key={'bookings-modal-' + openBookingModal} openState={openBookingModal}
                               newBooking={openNewBookingId}
                               newBookingValue={null}
                               bookingId={openBookingId} parentCallback={handleCallback}/>
            </React.Fragment>
        </>


    );
}