import * as React from "react";
import {useEffect, useState} from "react";
import DashboardRouter from "./DashboardRouter";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Login from "./account/Login";
import Logout from "./account/Logout";
import SelectLocation from "./account/SelectLocation";
import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import {Button} from "@mui/joy";
import {IsLastPaymentSuccess} from "../axios/Axios";

export default function App() {
    const [paymentLink, setPaymentLink] = useState<string>('');
    const [loadingPaymentLink, setLoadingPaymentLink] = useState<boolean>(false);
    const [lastPaymentSuccess, setLastPaymentSuccess] = useState<boolean>(true);

    useEffect(() => {
        const loadIsPaymentSuccess = async () => {
            let location = localStorage.getItem('location')?.toLowerCase().replace(' ', '_')
            let success = await IsLastPaymentSuccess(location)
            setLastPaymentSuccess(success['success'])
            setPaymentLink(success['failed_payment_invoice_url'])
        }
        loadIsPaymentSuccess()
    }, [])

    function createPaymentLink() {
        setLoadingPaymentLink(true)
        window.location.replace(paymentLink);
    }

    return (
        <>
            {!lastPaymentSuccess && (
                <Box sx={{
                    paddingTop: '5px',
                    paddingBottom: '5px',
                    background: 'red',
                    color: 'white',
                    textAlign: 'center'
                }}>
                    <Typography sx={{color: 'white '}} level={"title-md"}>Your ads are turned OFF as your last payment
                        didnt
                        go
                        through</Typography>


                    {!loadingPaymentLink ? (
                        <Button onClick={() => {
                            createPaymentLink()
                        }} sx={{marginTop: '5px'}}>Click here to confirm payment</Button>
                    ) : (
                        <Button endDecorator={<Button size="sm" variant="plain"
                                                      loading={loadingPaymentLink}/>} sx={{marginTop: '5px'}}>Click here
                            to confirm payment</Button>
                    )}
                </Box>
            )}

            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Login/>}/>
                    <Route path="/select-location" element={<SelectLocation/>}/>
                    <Route path="/logout" element={<Logout/>}/>
                    <Route path="/dashboard/*" element={<DashboardRouter/>}/>
                </Routes>
            </BrowserRouter>
        </>
    )
}