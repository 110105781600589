import React, {useEffect} from "react";
import {CircularProgress, ListItemDecorator, Modal, ModalClose, ModalDialog, Radio, RadioGroup, Select} from "@mui/joy";
import Box from "@mui/joy/Box";
import {
    CreateBooking,
    DeleteBookingById,
    GetAllBookings,
    GetAllServicePrices,
    GetBookingById,
    GetRecurringBookingByBookingId,
    UpdateBooking
} from "../../axios/Bookings-Axios";
import Input from "@mui/joy/Input";
import Option from "@mui/joy/Option";
import Typography from "@mui/joy/Typography";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import {CleanHands, CleaningServices, DeleteForever, InfoOutlined, Roofing, SolarPower} from "@mui/icons-material";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import Tooltip from "@mui/joy/Tooltip";
import Button from "@mui/joy/Button";
import ConfirmDeleteModal from "./ConfirmDeleteModal";
import CustomersTopComponent from "./CustomersTopComponent";
import { INITIAL_SERVICES } from "../../domain/Services";

interface Service {
    name: string;
    label: string;
    icon: React.ReactNode;
    defaultPrice: number;
    selected: boolean;
    price: number;
}

interface ServiceState {
    [key: string]: Service;
}

interface RecurringBooking {
    created_at: string;
    start_date: Date | null;
    id: number;
    service: {
        id: number;
        name: string;
    };
    interval: string;
    frequency: string;
    active: boolean;
    customer: Customer;
    service_price: number | null;
}

interface Customer {
    advertisement_platform?: string;
    created_at: string;
    email: string;
    full_name: string;
    house_type: {name: string, id: number};
    id: number;
    notes: string;
    phone: string;
    postcode: string;
    street_address: string;
    franchise_postcode?: {
        franchise: {
            location: string;
        };
        service_price_tier?: {
            roof_clean_price: number;
            gutter_clean_price: number;
            conservatory_gutter_clean_price: number;
            windows_sills_upvc_price: number;
            facias_soffits_outside_gutters_price: number;
            conservatory_windows_roof_and_upvc_price: number;
        };
    };
}

interface Booking {
    booked_in: string;
    created_at: string;
    customer: Customer;
    id: number;
    job_status: string;
    is_recurring: boolean;
    invoice_id: string;
    notes: string;
    paid: boolean;
    payment_type?: string;
    services: Array<{name: string} | string>;
    service_price: Array<{service: {name: string}, price: number}>;
    recurring_booking: RecurringBooking[] | null;
}

function formatDate(old_date: string | Date) {
    let date = new Date(old_date);
    if (!isNaN(date.getTime())) {
        return date.toLocaleString('en-US', {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric'
        });
    } else {
        return 'No Valid Date';
    }
}

const formatServices = (services: Array<{name: string} | string>): string => {
    const serviceNames = services.map(service => typeof service === 'string' ? service : service.name);
    if (serviceNames.length === 0) return "No service selected.";
    if (serviceNames.length === 1) return serviceNames[0];
    if (serviceNames.length === 2) return `${serviceNames[0]} and ${serviceNames[1]}`;
    const lastService = serviceNames.pop();
    return `${serviceNames.join(', ')}, and ${lastService}`;
}

export default function BookingsModal(
    {openState, bookingId, parentCallback, newBooking, newBookingValue}: {
        openState: boolean;
        bookingId: number;
        parentCallback: () => void;
        newBooking: Booking | null;
        newBookingValue?: any;
    }
) {
    const [open, setOpen] = React.useState(openState);
    const [loadedData, setLoadedData] = React.useState(false);
    const [loadedOtherBookings, setLoadedOtherBookings] = React.useState(false);
    const [otherBookings, setOtherBookings] = React.useState<Booking[]>([]);
    const [openDeleteModal, setOpenDeleteModal] = React.useState<boolean>(false);
    const [recurringBookings, setRecurringBookings] = React.useState<RecurringBooking[]>([]);

    const [customer, setCustomer] = React.useState<Customer>({
        advertisement_platform: undefined,
        created_at: "",
        email: "",
        full_name: "",
        house_type: {name: 'Detached', id: 1},
        id: 0,
        notes: "",
        phone: "",
        postcode: "",
        street_address: ""
    });

    const [booking, setBooking] = React.useState<Booking>({
        booked_in: new Date().toISOString(),
        created_at: new Date().toISOString(),
        customer: customer,
        id: -1,
        job_status: "",
        is_recurring: false,
        invoice_id: '',
        notes: '',
        paid: false,
        payment_type: undefined,
        services: [],
        service_price: [],
        recurring_booking: null
    });

    const [services, setServices] = React.useState<ServiceState>(INITIAL_SERVICES);

    const totalPrice = Object.values(services).reduce(
        (sum, service) => sum + (service.selected ? service.price : 0),
        0
    );

    const handleServiceToggle = (serviceKey: string) => {
        setServices(prev => {
            const newState = {
                ...prev,
                [serviceKey]: {
                    ...prev[serviceKey],
                    selected: !prev[serviceKey].selected,
                    price: !prev[serviceKey].selected ? prev[serviceKey].defaultPrice : 0
                }
            };

            if (!newState[serviceKey].selected) {
                removeRecurringBooking(newState[serviceKey].name);
            }
            return newState;
        });
    };

    const handlePriceChange = (serviceKey: string, value: string) => {
        const price = parseFloat(value) || 0;
        setServices(prev => ({
            ...prev,
            [serviceKey]: {
                ...prev[serviceKey],
                price: price
            }
        }));
    };

    function addRecurringBooking(service: string, interval: string, frequency: string) {
        const newBooking: RecurringBooking = {
            created_at: "",
            start_date: null,
            id: 0,
            service: {
                id: 1,
                name: service,
            },
            interval: interval,
            frequency: frequency,
            active: true,
            customer: customer,
            service_price: null
        };
        setRecurringBookings(prev => {
            const updated = prev.filter(booking => booking.service.name !== service);
            return [...updated, newBooking];
        });
    }

    function removeRecurringBooking(service: string) {
        setRecurringBookings(prev =>
            prev.filter(booking => booking.service.name !== service)
        );
    }

    const handleAddRecurringBooking = (newValue: string | null, name: string) => {
        if (newValue === 'one-time') {
            removeRecurringBooking(name);
        } else if (newValue) {
            const [interval, frequency] = newValue.split(' ');
            addRecurringBooking(name, interval, frequency);
        }
    };

    const handleDefaultValue = (name: string) => {
        const booking = recurringBookings.find(b => b.service.name === name);
        return booking ? `${booking.interval} ${booking.frequency}` : 'one-time';
    };

    const handleColor = (time: Date) => {
        return time.getHours() > 12 ? "" : "text-error";
    };

    let handleDeleteCallBack = async (doDelete: boolean) => {
        setOpenDeleteModal(false);
        if (!doDelete) return;

        await DeleteBookingById(bookingId);
        window.location.reload(false);
    };

    function closeModal() {
        setOpen(false);
        parentCallback();
    }

    const handleSave = async (event: React.FormEvent) => {
        event.preventDefault();
        if (booking.id !== -1) {
            await UpdateBooking(bookingId, booking, services, recurringBookings);
        } else {
            await CreateBooking(booking, services, recurringBookings);
        }
        window.location.reload(false);
    };

    useEffect(() => {
        setBooking(prev => ({
            ...prev,
            customer: customer
        }));
    }, [customer]);

    useEffect(() => {
        setLoadedOtherBookings(false)

        const fetchData = async () => {
            let bookedIn = new Date(String(booking?.booked_in));
            if (bookedIn && booking.customer?.franchise_postcode?.franchise?.location) {
                let day = bookedIn.getDate().toString().padStart(2, '0');
                let month = (bookedIn.getMonth() + 1).toString().padStart(2, '0');
                let year = bookedIn.getFullYear().toString();
                let location = localStorage.getItem('location')?.toLowerCase().replace(' ', '_')
                let bookingResponse = await GetAllBookings([location], 1, null, `${year}-${month}-${day}`);
                if (bookingResponse && bookingResponse['results'].length > 0) {
                    setOtherBookings(bookingResponse['results'])
                } else {
                    setOtherBookings([])
                }
            }
            setLoadedOtherBookings(true)
        }
        fetchData()


    }, [booking.booked_in])

    useEffect(() => {
        if (newBooking != null && newBooking.id === -1 && bookingId === -1) {
            return;
        }
        if (bookingId === 0) {
            return;
        }
        setLoadedData(false)
        const fetchData = async () => {
            const servicePriceTier = await GetAllServicePrices('D', 'Detached');

            if (newBooking !== null && newBooking != -1) {

                if (!('notes' in newBooking['customer'])) {
                    newBooking['customer']['notes'] = ''
                }
                setCustomer(newBooking.customer);
                setServices(newBookingValue)
                setLoadedData(true);
                return;
            }

            if (bookingId !== -1) {
                const [recurringBookings, bookingData] = await Promise.all([
                    GetRecurringBookingByBookingId(bookingId),
                    GetBookingById(bookingId)
                ]);

                setRecurringBookings(recurringBookings);

                if (bookingData.customer.notes == null) {
                    bookingData.customer.notes = '';
                }
                setCustomer(bookingData.customer);
                setBooking(bookingData);

                const franchisePrices = bookingData.customer?.franchise_postcode?.service_price_tier;
                const defaultPrices = {
                    conservatoryWindowsRoofAndUPVCDoors: franchisePrices?.conservatory_windows_roof_and_upvc_price ?? servicePriceTier[0].conservatory_windows_roof_and_upvc_price ?? 0,
                    faciasSoffitsAndOutsideGutters: franchisePrices?.facias_soffits_outside_gutters_price ?? servicePriceTier[0].facias_soffits_outside_gutters_price ?? 0,
                    windowSillsAndUPVCDoors: franchisePrices?.windows_sills_upvc_price ?? servicePriceTier[0].windows_sills_upvc_price ?? 0,
                    gutterClean: franchisePrices?.gutter_clean_price ?? servicePriceTier[0].gutter_clean_price ?? 0,
                    conservatoryGutterClean: franchisePrices?.conservatory_gutter_clean_price ??
                        servicePriceTier[0].conservatory_gutter_clean_price ?? 0
                };

                setServices(prev => {
                    const updated = {...prev};
                    for (const key in updated) {
                        const serviceName = updated[key].name;
                        updated[key] = {
                            ...updated[key],
                            selected: bookingData.services.some(s =>
                                typeof s === 'string' ? s === serviceName : s.name === serviceName
                            ),
                            price: bookingData.service_price?.find(item =>
                                typeof item.service === 'string'
                                    ? item.service === serviceName
                                    : item.service.name === serviceName
                            )?.price ?? defaultPrices[key] ?? 0,
                            defaultPrice: defaultPrices[key] ?? 0
                        };
                    }
                    return updated;
                });
            } else {
                setServices(prev => {
                    const updated = {...prev};
                    for (const key in updated) {
                        updated[key] = {
                            ...updated[key],
                            defaultPrice: servicePriceTier[0][`${key}Price`] ?? 0,
                            price: servicePriceTier[0][`${key}Price`] ?? 0
                        };
                    }
                    return updated;
                });
            }

            setLoadedData(true);
        };

        fetchData();
    }, [bookingId, newBooking, newBookingValue]);

    const renderServiceItem = (serviceKey: string) => {
        const service = services[serviceKey];
        return (
            <ListItem variant="outlined" key={service.name + '-service'} sx={{boxShadow: 'sm'}}>
                <ListItemDecorator>
                    {service.icon}
                </ListItemDecorator>
                <RadioGroup sx={{width: '100%'}}>
                    <Radio
                        overlay
                        checked={service.selected}
                        value={service.name}
                        label={service.label}
                        onClick={() => handleServiceToggle(serviceKey)}
                        sx={{flexGrow: 1, flexDirection: 'row-reverse'}}
                        slotProps={{
                            action: ({checked}) => ({
                                sx: (theme) => ({
                                    ...(checked && {
                                        inset: -1,
                                        border: '2px solid',
                                        borderColor: theme.vars.palette.primary[500],
                                    }),
                                }),
                            }),
                        }}
                    />
                </RadioGroup>
            </ListItem>
        );
    };

    const renderPriceInput = (serviceKey: string) => {
        const service = services[serviceKey];
        return (
            <ListItem key={service.label + '-price'} sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                <Typography>
                    {service.label} Price
                </Typography>
                <Input
                    startDecorator={'£'}
                    sx={{maxWidth: '200px'}}
                    type="number"
                    value={service.selected ? service.price.toString() : '0'}
                    onChange={(e) => handlePriceChange(serviceKey, e.target.value)}
                    disabled={!service.selected}
                />
            </ListItem>
        );
    };

    const renderRecurringBookingSelect = (serviceKey: string) => {
        const service = services[serviceKey];
        if (!service.selected) return null;

        // Define all possible options
        const allOptions = {
            gutterClean: [
                { value: "one-time", label: "One Time Booking" },
                { value: "4 week", label: "Every 4 Weeks" },
                { value: "6 week", label: "Every 6 Weeks" },
                { value: "8 week", label: "Every 8 Weeks" },
                { value: "12 week", label: "Every 12 Weeks" }
            ],
            conservatoryGutterClean: [
                { value: "one-time", label: "One Time Booking" },
                { value: "4 week", label: "Every 4 Weeks" },
                { value: "6 week", label: "Every 6 Weeks" },
                { value: "8 week", label: "Every 8 Weeks" },
                { value: "12 week", label: "Every 12 Weeks" }
            ],
            default: [
                { value: "one-time", label: "One Time Booking" },
                { value: "3 month", label: "Every 3 Months" },
                { value: "4 month", label: "Every 4 Months" },
                { value: "6 month", label: "Every 6 Months" },
                { value: "12 month", label: "Every 12 Months" }
            ]
        };

        const serviceOptions = allOptions[serviceKey as keyof typeof allOptions] || allOptions.default;

        const currentValue = handleDefaultValue(service.name) || 'one-time';

        return (
            <>
                <Typography sx={{ marginBottom: '5px', marginTop: '5px', width: '100%' }}>
                    {service.label}
                </Typography>
                <Select
                    value={currentValue}
                    onChange={(e, newValue) => handleAddRecurringBooking(newValue, service.name)}
                >
                    {serviceOptions.map(option => (
                        <Option key={option.value} value={option.value}>
                            {option.label}
                        </Option>
                    ))}
                </Select>
            </>
        );
    };

    return (
        <>
            <Modal slotProps={{
                backdrop: {
                    sx: {
                        backdropFilter: 'none',
                        backgroundColor: 'rgb(108 108 108 / 60%)',
                        padding: '10px'
                    },
                },
            }} open={open} onClose={() => closeModal()}>
                <ModalDialog sx={{overflowY: 'scroll'}}>
                    <form onSubmit={handleSave} id="a-form">
                        <ModalClose/>
                        <Box sx={{
                            minWidth: 600,
                            display: 'flex',
                            flexDirection: 'column',
                            overflowX: 'hidden',
                        }}>
                        </Box>

                        {!loadedData ? (
                            <Box sx={{
                                display: 'flex',
                                gap: 2,
                                alignItems: 'center',
                                flexWrap: 'wrap',
                                minWidth: '50%',
                                height: '100%',
                                justifyContent: 'center'
                            }}>
                                <CircularProgress size="lg"/>
                            </Box>
                        ) : (
                            <Box>
                                <CustomersTopComponent customer={customer} setCustomer={setCustomer}/>

                                <Box sx={{marginTop: '10px'}}>
                                    <Typography variant="soft" sx={{marginBottom: '5px', width: '100%'}}>
                                        Services Selected
                                    </Typography>
                                    <Box sx={{display: "flex"}}>
                                        <Box sx={{
                                            minHeight: '100%',
                                            display: "flex",
                                            flexDirection: "row",
                                            minWidth: '100%',
                                        }}>
                                            <Box sx={{minWidth: '50%', marginRight: "15px"}}>
                                                <List
                                                    sx={{
                                                        '--List-gap': '0.5rem',
                                                        '--ListItem-paddingY': '1rem',
                                                        '--ListItem-radius': '8px',
                                                        '--ListItemDecorator-size': '32px',
                                                    }}
                                                >
                                                    {Object.keys(services).map(serviceKey =>
                                                        renderServiceItem(serviceKey)
                                                    )}
                                                </List>
                                            </Box>
                                            <Box sx={{
                                                minWidth: '50%',
                                                marginBottom: "5px",
                                            }}>
                                                <Box>
                                                    {Object.keys(services).map(serviceKey =>
                                                        renderPriceInput(serviceKey)
                                                    )}
                                                </Box>
                                                <Typography level="title-md">
                                                    Total Price: £{totalPrice}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>

                                {Object.values(services).some(s => s.selected) && (
                                    <Box sx={{marginTop: '10px'}}>
                                        <Typography
                                            startDecorator={
                                                <Tooltip title="Recurring Bookings will be created a year in advance">
                                                    <InfoOutlined/>
                                                </Tooltip>
                                            }
                                            variant="soft"
                                            sx={{marginBottom: '5px', width: '100%'}}
                                        >
                                            Recurring Booking
                                        </Typography>
                                        {Object.keys(services)
                                            .filter(key => services[key].selected)
                                            .map(key => renderRecurringBookingSelect(key))
                                        }
                                    </Box>
                                )}

                                <Box sx={{marginTop: '10px'}}>
                                    <Typography variant="soft" sx={{marginBottom: '5px', width: '100%'}}>
                                        Booking Time
                                    </Typography>

                                    <Box sx={{display: 'flex', width: '100%', minWidth: '328px'}}>
                                        <DatePicker
                                            showTimeSelect
                                            selected={new Date(String(booking?.booked_in))}
                                            onChange={(date: Date) => {
                                                setBooking(prev => ({
                                                    ...prev,
                                                    booked_in: date.toISOString()
                                                }));
                                            }}
                                            timeClassName={handleColor}
                                            inline
                                        />

                                        {loadedOtherBookings ? (
                                            <Box sx={{marginLeft: '10px', maxWidth: '250px'}}>
                                                <Typography>Other Bookings That Day:</Typography>
                                                {otherBookings.length === 0 ? (
                                                    <Typography>No bookings found</Typography>
                                                ) : (
                                                    <List
                                                        sx={{
                                                            borderRadius: 'sm',
                                                            overflowY: 'scroll',
                                                            maxHeight: '200px'
                                                        }}
                                                    >
                                                        {otherBookings.map((booking, index) => (
                                                            <Tooltip
                                                                key={index}
                                                                title={formatServices(booking.services)}
                                                                arrow
                                                                placement="right"
                                                            >
                                                                <ListItem key={index} sx={{marginBottom: '5px'}} variant="outlined">
                                                                    {booking.customer?.full_name} - {formatDate(new Date(String(booking.booked_in)))}
                                                                </ListItem>
                                                            </Tooltip>
                                                        ))}
                                                    </List>
                                                )}
                                            </Box>
                                        ) : (
                                            <Box sx={{
                                                display: 'flex',
                                                gap: 2,
                                                alignItems: 'center',
                                                flexWrap: 'wrap',
                                                minWidth: '40%',
                                                justifyContent: 'center'
                                            }}>
                                                <CircularProgress size="lg"/>
                                            </Box>
                                        )}
                                    </Box>
                                </Box>
                            </Box>
                        )}

                        <Box sx={{display: 'flex', justifyContent: 'flex-end', width: '100%'}}>
                            {loadedData && bookingId !== -1 && (
                                <>
                                    <Button
                                        sx={{marginLeft: 'auto'}}
                                        color="danger"
                                        endDecorator={<DeleteForever/>}
                                        onClick={() => setOpenDeleteModal(true)}
                                    >
                                        Delete
                                    </Button>
                                    <Button type="submit" sx={{marginLeft: '10px'}}>
                                        Save Booking
                                    </Button>
                                </>
                            )}
                            {loadedData && bookingId === -1 && (
                                <Button type="submit" sx={{marginLeft: 'auto'}}>
                                    Create New Booking
                                </Button>
                            )}
                        </Box>
                    </form>
                </ModalDialog>
            </Modal>
            <React.Fragment>
                <ConfirmDeleteModal
                    discardMessage={"Are you sure you want to delete this booking?"}
                    openState={openDeleteModal}
                    parentCallback={handleDeleteCallBack}
                />
            </React.Fragment>
        </>
    );
}