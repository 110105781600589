import * as React from 'react';
import {useEffect, useState} from 'react';
import GlobalStyles from '@mui/joy/GlobalStyles';
import Avatar from '@mui/joy/Avatar';
import Box from '@mui/joy/Box';
import Divider from '@mui/joy/Divider';
import IconButton from '@mui/joy/IconButton';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemButton, {listItemButtonClasses} from '@mui/joy/ListItemButton';
import ListItemContent from '@mui/joy/ListItemContent';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import SupportRoundedIcon from '@mui/icons-material/SupportRounded';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import AssignmentRoundedIcon from '@mui/icons-material/AssignmentRounded';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import yetiLogo from '../../images/yeticlean-logo.png';
import {closeSidebar} from '../../utils';
import {useLocation, useNavigate} from "react-router-dom";
import {GetToggledAds, GetUser, LogoutUser} from "../../axios/Axios";
import {
    AutorenewRounded,
    BookOutlined,
    CalendarMonthRounded,
    CallOutlined,
    ContactEmergencyRounded,
    CreditCard, CurrencyExchange, CurrencyPound, CurrencyPoundTwoTone, LeaderboardOutlined,
    MarkChatRead,
    MessageOutlined,
    MoneyRounded,
    Payments,
    Settings,
    StickyNote2Rounded
} from "@mui/icons-material";
import {AxiosResponse} from "axios";
import {Chip} from "@mui/joy";
import ColorSchemeToggle from "../ColorSchemeToggle";

interface User {
    first_name: string;
    last_name: string;
    username: string;
}

interface Franchise {
    name: string;
    gutter_ads_status: string;
    roof_ads_status: string;
}

function Toggler({
                     defaultExpanded = false,
                     renderToggle,
                     children,
                 }: {
    defaultExpanded?: boolean;
    children: React.ReactNode;
    renderToggle: (params: {
        open: boolean;
        setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    }) => React.ReactNode;
}) {
    const [open, setOpen] = React.useState(defaultExpanded);
    return (
        <React.Fragment>
            {renderToggle({open, setOpen})}
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateRows: open ? '1fr' : '0fr',
                    transition: '0.2s ease',
                    '& > *': {
                        overflow: 'hidden',
                    },
                }}
            >
                {children}
            </Box>
        </React.Fragment>
    );
}

export default function SideNav() {
    const location = useLocation();
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);
    const [user, setUser] = useState<User>({first_name: '', last_name: '', username: ''});
    const [selectedFranchise, setFranchiseSelected] = React.useState<Franchise>();

    let selectedLocation = localStorage.getItem('location')

    useEffect(() => {
        const fetchData = async () => {
            if (selectedLocation != 'All') {
                let data = await GetToggledAds(selectedLocation);
                setFranchiseSelected(data)
            }

            GetUser().then((response) => {
                if (response.status === 200) {
                    setLoaded(true);
                    setUser(response.data)
                }
            }).catch((err) => {
                setLoaded(true);
            })
        };
        fetchData();
    }, [setLoaded]);

    function isCurrentLocation(locationCheck: String) {
        return location.pathname === locationCheck;
    }

    function logout() {
        LogoutUser().then((response: AxiosResponse<any>) => {
            navigate("/");
        })
    }

    function purchaseLeads() {
        navigate("/dashboard/lead-generation");
    }


    return (
        <Sheet
            className="Sidebar"
            sx={{
                position: {xs: 'fixed', md: 'sticky'},
                transform: {
                    xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1)))',
                    md: 'none',
                },
                transition: 'transform 0.4s, width 0.4s',
                zIndex: 10000,
                height: '100dvh',
                width: 'var(--Sidebar-width)',
                top: 0,
                p: 2,
                flexShrink: 0,
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                borderRight: '1px solid',
                borderColor: 'divider',
                boxSizing: 'border-box'
            }}
        >
            <GlobalStyles
                styles={(theme) => ({
                    ':root': {
                        '--Sidebar-width': '220px',
                        [theme.breakpoints.up('lg')]: {
                            '--Sidebar-width': '240px',
                        },
                    },
                })}
            />
            <Box
                className="Sidebar-overlay"
                sx={{
                    position: 'fixed',
                    zIndex: 9998,
                    top: 0,
                    left: 0,
                    width: '100vw',
                    height: '100vh',
                    opacity: 'var(--SideNavigation-slideIn)',
                    backgroundColor: 'var(--joy-palette-background-backdrop)',
                    transition: 'opacity 0.4s',
                    transform: {
                        xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--Sidebar-width, 0px)))',
                        lg: 'translateX(-100%)',
                    },
                }}
                onClick={() => closeSidebar()}
            />

            <Box sx={{display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center'}}>
                <img style={{width: '100%'}} src={yetiLogo}
                     alt="Yeti Clean Logo"/>
            </Box>
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: 1,
                width: '100%',
                textAlign: 'center',
                justifyContent: 'center'
            }}>
                <Typography level="title-md">{selectedLocation}</Typography>
            </Box>
            <Box
                sx={{
                    minHeight: 0,
                    overflow: 'hidden auto',
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    [`& .${listItemButtonClasses.root}`]: {
                        gap: 1.5,
                    },
                }}
            >
                <List
                    size="sm"
                    sx={{
                        gap: 1,
                        '--List-nestedInsetStart': '30px',
                        '--ListItem-radius': (theme) => theme.vars.radius.sm,
                    }}
                >
                    {/*<ListItem>*/}
                    {/*    <ListItemButton*/}
                    {/*        selected={isCurrentLocation('/dashboard')}*/}
                    {/*        component="a"*/}
                    {/*        href="/dashboard">*/}
                    {/*        <HomeRoundedIcon/>*/}
                    {/*        <ListItemContent>*/}
                    {/*            <Typography level="title-sm">HomeNew</Typography>*/}
                    {/*        </ListItemContent>*/}
                    {/*    </ListItemButton>*/}
                    {/*</ListItem>*/}
                    <ListItem sx={{marginTop: '5px'}}>
                        <ListItemContent>
                            <Typography level="body-sm" fontWeight="lg">Operations</Typography>
                        </ListItemContent>
                    </ListItem>
                    <ListItem>
                        <ListItemButton selected={isCurrentLocation('/dashboard/leads')}
                                        component="a"
                                        href="/dashboard/leads">
                            <ContactEmergencyRounded/>
                            <ListItemContent>
                                <Typography level="title-sm">Leads</Typography>
                            </ListItemContent>
                        </ListItemButton>
                    </ListItem>
                    <ListItem nested>
                        <Toggler
                            defaultExpanded={false}
                            renderToggle={({open, setOpen}) => (
                                <ListItemButton onClick={() => setOpen(!open)}>
                                    <AssignmentRoundedIcon/>
                                    <ListItemContent>
                                        <Typography level="title-sm">Bookings</Typography>
                                    </ListItemContent>
                                    <KeyboardArrowDownIcon
                                        sx={[
                                            open
                                                ? {
                                                    transform: 'rotate(180deg)',
                                                }
                                                : {
                                                    transform: 'none',
                                                },
                                        ]}
                                    />
                                </ListItemButton>
                            )}
                        >
                            <List>
                                <ListItem>
                                    <ListItemButton selected={isCurrentLocation('/dashboard/bookings')}
                                                    component="a"
                                                    href="/dashboard/bookings">
                                        <StickyNote2Rounded/>
                                        <ListItemContent>
                                            <Typography level="title-sm">All Bookings</Typography>
                                        </ListItemContent>
                                    </ListItemButton>
                                </ListItem>
                                <ListItem>
                                    <ListItemButton selected={isCurrentLocation('/dashboard/recurring-bookings')}
                                                    component="a"
                                                    href="/dashboard/recurring-bookings">
                                        <AutorenewRounded/>
                                        <ListItemContent>
                                            <Typography level="title-sm">Recurring Bookings</Typography>
                                        </ListItemContent>
                                    </ListItemButton>
                                </ListItem>
                            </List>
                        </Toggler>
                    </ListItem>
                    <ListItem>
                        <ListItemButton
                            selected={isCurrentLocation('/dashboard/todays-bookings')}
                            component="a"
                            href="/dashboard/todays-bookings">
                            <MarkChatRead/>
                            <ListItemContent>
                                <Typography level="title-sm">Todays Bookings</Typography>
                            </ListItemContent>
                        </ListItemButton>
                    </ListItem>
                    <ListItem>
                        <ListItemButton
                            selected={isCurrentLocation('/dashboard/calendar')}
                            component="a"
                            href="/dashboard/calendar">
                            <CalendarMonthRounded/>
                            <ListItemContent>
                                <Typography level="title-sm">Booking Calendar</Typography>
                            </ListItemContent>
                        </ListItemButton>
                    </ListItem>


                    <ListItem sx={{marginTop: '5px'}}>
                        <ListItemContent>
                            <Typography level="body-sm" fontWeight="lg">Customer Management</Typography>
                        </ListItemContent>
                    </ListItem>

                    <ListItem>
                        <ListItemButton selected={isCurrentLocation('/dashboard/customers')}
                                        component="a"
                                        href="/dashboard/customers">
                            <PersonRoundedIcon/>
                            <ListItemContent>
                                <Typography level="title-sm">Customers</Typography>
                            </ListItemContent>
                        </ListItemButton>
                    </ListItem>
                    <ListItem>
                        <ListItemButton
                            selected={isCurrentLocation('/dashboard/messages')}
                            component="a"
                            href="/dashboard/messages">
                            <MessageOutlined/>
                            <ListItemContent>
                                <Typography level="title-sm">Messages</Typography>
                            </ListItemContent>
                        </ListItemButton>
                    </ListItem>

                    <ListItem>
                        <ListItemButton selected={isCurrentLocation('/dashboard/calls')}
                                        component="a"
                                        href="/dashboard/calls">
                            <CallOutlined/>
                            <ListItemContent>
                                <Typography level="title-sm">Call Log</Typography>
                            </ListItemContent>
                        </ListItemButton>
                    </ListItem>

                    <ListItem sx={{marginTop: '5px'}}>
                        <ListItemContent>
                            <Typography level="body-sm" fontWeight="lg">Customer Invoices</Typography>
                        </ListItemContent>
                    </ListItem>
                    <ListItem>
                        <ListItemButton selected={isCurrentLocation('/dashboard/invoices')}
                                        component="a"
                                        href="/dashboard/invoices">
                            <CreditCard/>
                            <ListItemContent>
                                <Typography level="title-sm">Customer Invoices</Typography>
                            </ListItemContent>
                        </ListItemButton>
                    </ListItem>
                    <ListItem>
                        <ListItemButton selected={isCurrentLocation('/dashboard/payouts')}
                                        component="a"
                                        href="/dashboard/payouts">
                            <MoneyRounded/>
                            <ListItemContent>
                                <Typography level="title-sm">Your Payouts</Typography>
                            </ListItemContent>
                        </ListItemButton>
                    </ListItem>


                    <ListItem sx={{marginTop: '5px'}}>
                        <ListItemContent>
                            <Typography level="body-sm" fontWeight="lg">Franchise Management</Typography>
                        </ListItemContent>
                    </ListItem>
                    <ListItem>
                        <ListItemButton
                            selected={isCurrentLocation('/dashboard/franchise-settings')}
                            component="a"
                            href="/dashboard/franchise-settings">
                            <Settings/>
                            <ListItemContent>
                                <Typography level="title-sm">Franchise Settings</Typography>
                            </ListItemContent>
                        </ListItemButton>
                    </ListItem>



                    <ListItem sx={{marginTop: '5px'}}>
                        <ListItemContent>
                            <Typography level="body-sm" fontWeight="lg">Resources</Typography>
                        </ListItemContent>
                    </ListItem>
                    <ListItem>
                        <ListItemButton
                            selected={isCurrentLocation('/dashboard/knowledge-base')}
                            component="a"
                            href="/dashboard/knowledge-base">
                            <BookOutlined/>
                            <ListItemContent>
                                <Typography level="title-sm">Knowledge Base</Typography>
                            </ListItemContent>
                        </ListItemButton>
                    </ListItem>
                </List>

                <List
                    size="sm"
                    sx={{
                        mt: 'auto',
                        flexGrow: 0,
                        '--ListItem-radius': (theme) => theme.vars.radius.sm,
                        '--List-gap': '8px',
                    }}
                >
                    {selectedFranchise != null &&
                        <ListItem sx={{m: 0, p: 0, width: '100%'}}>
                            <Box sx={{justifyContent: 'center', width: '100%', alignItems: 'center'}}>
                                <Box onClick={() => purchaseLeads()} sx={{
                                    marginTop: '5px',
                                    marginBottom: '5px',
                                    cursor: 'pointer',
                                    display: 'flex',
                                    justifyContent: 'space-between'
                                }}>
                                    <Typography sx={{whiteSpace: 'nowrap'}} level="title-sm">GC
                                        Ads:</Typography>
                                    {selectedFranchise.gutter_ads_status === 'Off' ? (
                                        <Chip sx={{width: '100%', overflow: 'none'}} color="danger">Off</Chip>
                                    ) : selectedFranchise.gutter_ads_status === 'Pending' ? (
                                        <Chip sx={{width: '100%', overflow: 'none'}} color="warning">Pending
                                            Payment</Chip>
                                    ) : (
                                        <Chip sx={{width: '100%', overflow: 'none'}} color="success">On</Chip>
                                    )}
                                </Box>
                                <Box onClick={() => purchaseLeads()} sx={{
                                    whiteSpace: 'no-wrap',
                                    marginTop: '5px',
                                    marginBottom: '5px',
                                    cursor: 'pointer',
                                    display: 'flex',
                                    justifyContent: 'space-between'
                                }}>
                                    <Typography sx={{whiteSpace: 'nowrap'}} level="title-sm">RC
                                        Ads:</Typography>
                                    {selectedFranchise.roof_ads_status === 'Off' ? (
                                        <Chip sx={{width: '100%', overflow: 'none'}} color="danger">Off</Chip>
                                    ) : selectedFranchise.roof_ads_status === 'Pending' ? (
                                        <Chip sx={{width: '100%', overflow: 'none'}} color="warning">Pending
                                            Payment</Chip>
                                    ) : (
                                        <Chip sx={{width: '100%', overflow: 'none'}} color="success">On</Chip>
                                    )}
                                </Box>
                            </Box>
                        </ListItem>
                    }

                    <ListItem>

                        <ListItemButton component="a"
                                        href="/select-location">
                            <SupportRoundedIcon/>
                            Change Location
                        </ListItemButton>
                    </ListItem>
                </List>
            </Box>
            <Divider/>
            <Box sx={{display: 'flex', gap: 1, alignItems: 'center'}}>
                <Avatar
                    variant="outlined"
                    size="sm"
                />
                <Box sx={{minWidth: 0, flex: 1}}>
                    <Typography level="title-sm">{user.username}</Typography>
                </Box>
                <ColorSchemeToggle sx={{ml: 'auto'}}/>
                <IconButton size="sm" variant="outlined"
                            color="neutral">
                    <LogoutRoundedIcon onClick={() => logout()}/>
                </IconButton>
            </Box>
        </Sheet>
    );
}