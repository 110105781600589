import * as React from 'react';
import {useEffect} from 'react';
import Button from '@mui/joy/Button';
import Divider from '@mui/joy/Divider';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import DialogActions from '@mui/joy/DialogActions';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';

export default function ConfirmDeleteModal({openState, parentCallback, discardMessage}) {

    const [open, setOpen] = React.useState<boolean>(openState);

    useEffect(() => {
        setOpen(openState)
    }, [openState]);


    function actionDelete() {
        setOpen(false)
        parentCallback(true)
    }

    function actionCancel() {
        setOpen(false)
        parentCallback(false)
    }


    return (
        <React.Fragment>
            <Modal open={open} onClose={() => setOpen(false)}>
                <ModalDialog variant="outlined" role="alertdialog">
                    <DialogTitle>
                        <WarningRoundedIcon/>
                        Confirmation
                    </DialogTitle>
                    <Divider/>
                    <DialogContent>
                        {discardMessage}
                    </DialogContent>
                    <DialogActions>
                        <Button variant="solid" color="danger" onClick={() => actionDelete()}>
                            Delete
                        </Button>
                        <Button variant="plain" color="neutral" onClick={() => actionCancel()}>
                            Cancel
                        </Button>
                    </DialogActions>
                </ModalDialog>
            </Modal>
        </React.Fragment>
    );
}