import * as React from 'react';
import {useEffect, useState} from 'react';
import {Navigate, Route, Routes, useNavigate} from "react-router-dom";
import Leads from "./dashboard-pages/Leads";
import Bookings from "./dashboard-pages/Bookings";
import {GetUser, LogoutUser, VerifyLoggedIn} from "../axios/Axios";
import Customers from "./dashboard-pages/Customers";
import LeadGeneration from "./dashboard-pages/LeadGeneration";
import Box from "@mui/joy/Box";
import {CircularProgress} from "@mui/joy";
import Calendar from "./dashboard-pages/Calendar";
import RecurringBookings from "./dashboard-pages/Recurring-Bookings";
import Messages from "./dashboard-pages/messages/Messages";
import Invoices from "./dashboard-pages/Invoices";
import Payouts from "./dashboard-pages/Payouts";
import TodaysBookings from "./dashboard-pages/TodaysBookings";
import FranchiseSettings from "./dashboard-pages/FranchiseSettings";
import KnowledgeBase from "./dashboard-pages/KnowledgeBase";
import KnowledgeBaseCourseDetail from "./dashboard-pages/KnowledgeBaseCourseDetail";
import Home from "./dashboard-pages/Home";
import CallLog from "./dashboard-pages/CallLog";

interface User {
    location_permissions: string[];
}

const PERMISSIONS = {
    ADMIN: 'admin',
    EMPLOYEE: 'employee',
};

export default function DashboardRouter() {
    let navigate = useNavigate()
    const [authenticated, setAuthenticated] = useState(false);
    const [initializing, setInitializing] = useState(true);
    const [locationSet, setLocationSet] = useState(false);
    const [userPermissions, setUserPermissions] = useState<string[]>([]);


    useEffect(() => {
        const checkUserStatus = async () => {

            try {
                let localStorageLocationSet = localStorage.getItem('location') !== null;
                const response = await VerifyLoggedIn();
                if (response.status === 200) {
                    setAuthenticated(true);
                    const userResponse = await GetUser();
                    if (userResponse.status !== 200) {
                        return <Navigate to="/"/>;
                    }
                    if (localStorageLocationSet) {
                        let franchise = localStorage.getItem('location')
                        const permissions = userResponse.data.location_permissions;
                        setUserPermissions(permissions);

                        // @ts-ignore
                        let permission = userResponse.data.location_permissions.includes('franchise.location.' + franchise?.toLowerCase().replaceAll(' ', '_'))
                        if (userResponse.data.location_permissions.includes('franchise.location.all')) {
                            permission = true;
                        }
                        if (!permission) {
                            setLocationSet(false)
                        } else {
                            setLocationSet(true)
                        }
                    }
                }
            } catch (err) {
                await LogoutUser();
                navigate("/");
            } finally {
                setInitializing(false);
            }
        };

        checkUserStatus();
    }, []);


    if (initializing) {
        return <Box sx={{position: 'absolute', left: '50%', top: '50%'}}>
            <Box sx={{
                display: 'flex',
                gap: 2,
                alignItems: 'center',
                flexWrap: 'wrap',
                height: '100%',
                width: '100%',
                justifyContent: 'center'
            }}>
                <CircularProgress
                    size="lg"
                />
            </Box>
        </Box>;
    }
    if (!locationSet) {
        localStorage.removeItem('location')
        return <Navigate to="/select-location"/>;
    }
    if (!authenticated) {
        return <Navigate to="/"/>;
    }

    const renderRoutes = () => {
        if (userPermissions.includes(PERMISSIONS.ADMIN)) {
            return (
                <>
                    <Route path="/" element={<Home/>}/>
                    <Route path="/leads" element={<Leads/>}/>
                    <Route path="/customers" element={<Customers/>}/>
                    <Route path="/calls" element={<CallLog/>}/>
                    <Route path="/bookings" element={<Bookings/>}/>
                    <Route path="/recurring-bookings" element={<RecurringBookings/>}/>
                    <Route path="/lead-generation" element={<LeadGeneration/>}/>
                    <Route path="/knowledge-base" element={<KnowledgeBase/>}/>
                    <Route path="/knowledge-base/:slug" element={<KnowledgeBaseCourseDetail/>}/>
                    <Route path="/calendar" element={<Calendar/>}/>
                    <Route path="/messages" element={<Messages/>}/>
                    <Route path="/todays-bookings" element={<TodaysBookings/>}/>
                    <Route path="/invoices" element={<Invoices/>}/>
                    <Route path="/payouts" element={<Payouts/>}/>
                    <Route path="/franchise-settings" element={<FranchiseSettings/>}/>
                </>
            );
        }

        if (userPermissions.includes(PERMISSIONS.EMPLOYEE)) {
            return (
                <>
                    <Route path="/" element={<TodaysBookings/>}/>
                    <Route path="/todays-bookings" element={<TodaysBookings/>}/>
                </>
            );
        }
    };

    return (
        <Routes>
            {renderRoutes()}
        </Routes>
    );

}