import * as React from 'react';
import {useEffect, useState} from 'react';
import Box from '@mui/joy/Box';
import Sheet from '@mui/joy/Sheet';
import Stack from '@mui/joy/Stack';
import ChatBubble from './ChatBubble';
import MessagesPaneHeader from './MessagesPaneHeader';
import MessageInput from './MessageInput';
import {GetUser, PostSMSTextResponse} from "../../../axios/Axios";
import {GetAllServicePrices, GetLatestLeadByCustomerId,} from "../../../axios/Bookings-Axios";
import {Card, CardContent, CircularProgress} from "@mui/joy";
import Typography from "@mui/joy/Typography";
import BookingsModal from "../../modals/BookingsModal";
import LeadsModal from "../../modals/LeadsModal";
import Button from "@mui/joy/Button";
import {INITIAL_SERVICES, ServiceState} from "../../../domain/Services";

function formatDate(old_date, doTimeAgo) {
    let date = new Date(old_date);

    if (!isNaN(date.getTime())) {
        const now: Date = new Date();
        const diffInSeconds: number = Math.floor((now.getTime() - date.getTime()) / 1000);
        let timeAgo: string;
        if (diffInSeconds < 60) {
            timeAgo = `${diffInSeconds} second${diffInSeconds !== 1 ? 's' : ''} ago`;
        } else if (diffInSeconds < 3600) { // less than an hour
            const diffInMinutes = Math.floor(diffInSeconds / 60);
            timeAgo = `${diffInMinutes} minute${diffInMinutes !== 1 ? 's' : ''} ago`;
        } else if (diffInSeconds < 86400) { // less than a day
            const diffInHours = Math.floor(diffInSeconds / 3600);
            timeAgo = `${diffInHours} hour${diffInHours !== 1 ? 's' : ''} ago`;
        } else if (diffInSeconds < 604800) { // less than a week
            const diffInDays = Math.floor(diffInSeconds / 86400);
            timeAgo = `${diffInDays} day${diffInDays !== 1 ? 's' : ''} ago`;
        } else if (diffInSeconds < 2628000) { // less than a month
            const diffInWeeks = Math.floor(diffInSeconds / 604800);
            timeAgo = `${diffInWeeks} week${diffInWeeks !== 1 ? 's' : ''} ago`;
        } else if (diffInSeconds < 31536000) { // less than a year
            const diffInMonths = Math.floor(diffInSeconds / 2628000);
            timeAgo = `${diffInMonths} month${diffInMonths !== 1 ? 's' : ''} ago`;
        } else { // more than a year
            const diffInYears = Math.floor(diffInSeconds / 31536000);
            timeAgo = `${diffInYears} year${diffInYears !== 1 ? 's' : ''} ago`;
        }

        return `${date.toLocaleString('en-US', {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric'
        })}${doTimeAgo ? ` (${timeAgo})` : ''}`;
    } else {
        return 'No Valid Date';
    }
}


type MessagesPaneProps = {
    chat: ChatProps;
};

interface User {
    username: string;
}

export default function MessagesPane(props: MessagesPaneProps) {
    const {chat} = props;
    const [loadedData, setLoadingData] = React.useState(true);
    const [chatMessages, setChatMessages] = React.useState(chat.messages);
    const [textAreaValue, setTextAreaValue] = React.useState('');
    const [user, setUser] = useState<User>({username: ''});
    const [lead, setLead] = React.useState <Lead>({
        created_at: "",
        customer: undefined,
        id: -1,
        notes: undefined,
        paid: false,
        services: [],
        service_price: [],
        status: []
    });

    const [leadValue, setLeadValue] = React.useState <ServiceValue>({
        conservatoryGutterCleanPrice: 0, gutterCleanPrice: 0, roofCleanPrice: 0,
        conservatoryGutterClean: false,
        gutterClean: false,
        price: 0,
        roofClean: false
    })
    const [seed, setSeed] = useState<number>(0);

    useEffect(() => {
        const newSeed = Math.random();
        setSeed(newSeed);
    }, [lead]);

    useEffect(() => {
        if (chatMessages.length == 0) {
            setLoadingData(false)
            return;
        }
        setLoadingData(true)
        const fetchData = async () => {
            const userResponse = await GetUser();
            setUser(userResponse.data);
            let message = chatMessages.find(msg => msg.sender.id !== null);
            if(message && message.sender.id){
                const lead = await GetLatestLeadByCustomerId(message.sender.id);
                setLead(lead)
                const servicePriceTier = await GetAllServicePrices('D', 'Detached');
                const defaultRoofCleanPrice = lead?.customer?.franchise_postcode?.service_price_tier?.roof_clean_price
                    || servicePriceTier[0].roof_clean_price;

                const defaultConservatoryGutterCleanPrice = lead?.customer?.franchise_postcode?.service_price_tier?.conservatory_gutter_clean_price
                    || servicePriceTier[0].conservatory_gutter_clean_price;

                const defaultGutterCleanPrice = lead?.customer?.franchise_postcode?.service_price_tier?.gutter_clean_price
                    || servicePriceTier[0].gutter_clean_price;

                setLeadValue(prevLead => ({
                    ...prevLead,
                    gutterClean: lead.services.some(service => service.name === "Gutter Clean"),
                    roofClean: lead.services.some(service => service.name === "Roof Clean"),
                    conservatoryGutterClean: lead.services.some(service => service.name === "Conservatory Gutter Clean"),
                    conservatoryGutterCleanPrice: lead.service_price?.find(item => item.service.name === "Conservatory Gutter Clean")?.price || defaultConservatoryGutterCleanPrice,
                    gutterCleanPrice: lead.service_price?.find(item => item.service.name === "Gutter Clean")?.price || defaultGutterCleanPrice,
                    roofCleanPrice: lead.service_price?.find(item => item.service.name === "Roof Clean")?.price || defaultRoofCleanPrice,
                }));
            }

            setLoadingData(false)
        };
        fetchData()
    }, []);


    useEffect(() => {
        setChatMessages(chat.messages);
    }, [chat.messages]);

    function addChatMessage() {
        const newId = chatMessages.length + 1;
        const newIdString = newId.toString();
        let newChatMessage = {
            id: newIdString,
            sender: {
                id: '-1',
                name: user.username,
                phone: chat.sender.phone,
                isCustomer: false,
                username: user.username,
            },
            content: textAreaValue,
            timestamp: new Date().toISOString(),
        }
        setChatMessages([
            ...chatMessages,
            newChatMessage,
        ]);
        PostSMSTextResponse(newChatMessage)

    }

    //booking modal
    const [openBookingModal, setOpenBookingModal] = React.useState(false);
    const [openBookingId, setOpenBookingId] = React.useState(0);
    const handleCloseBookingModal = () => {
        setOpenBookingModal(false)
    };
    const handleOpenBookingModal = (id) => {
        setOpenBookingModal(false);
        setTimeout(() => {
            setOpenBookingModal(true);
            setOpenBookingId(id);
        }, 100);
    };
    //lead modal
    const [openLeadId, setOpenLeadId] = React.useState(0);
    const [openLeadModal, setOpenLeadModal] = React.useState(false);
    const handleCloseLeadModal = () => {
        setOpenLeadModal(false)
    };
    const handleOpenLeadModal = (id) => {
        setOpenLeadModal(false);
        setTimeout(() => {
            setOpenLeadModal(true);
            setOpenLeadId(id);
        }, 100);
    };





    return (
        <Sheet
            sx={{
                height: {xs: 'calc(90dvh - var(--Header-height))', md: '90dvh'},
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: 'background.level1',
            }}
        >
            <React.Fragment>
                <BookingsModal key={'bookings-modal-' + openBookingModal} openState={openBookingModal} newBooking={null}
                               newBookingValue={null}
                               bookingId={openBookingId} parentCallback={handleCloseBookingModal}/>
            </React.Fragment>

            <React.Fragment>
                <LeadsModal key={'leads-modal-' + openLeadModal} openState={openLeadModal}
                            leadId={openLeadId} parentCallback={handleCloseLeadModal}/>
            </React.Fragment>

            {loadedData ? (
                <Box sx={{
                    display: 'flex',
                    gap: 2,
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    minWidth: '50%',
                    height: '100%',
                    justifyContent: 'center'
                }}>
                    <CircularProgress
                        size="lg"
                    />
                </Box>
            ) : (
                <>

                    <MessagesPaneHeader sender={chat.sender}/>
                    <Box
                        sx={{
                            display: 'flex',
                            flex: 1,
                            minHeight: 0,
                            px: 2,
                            py: 3,
                            overflowY: 'scroll',
                            flexDirection: 'column-reverse',
                        }}
                    >
                        <Stack spacing={2} sx={{justifyContent: 'flex-end'}}>
                            {chatMessages
                                .sort((a: MessageProps, b: MessageProps) =>
                                    new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
                                )
                                .map((message: MessageProps, index: number) => {
                                    if (message.leadData || message.bookingData) {
                                        return (
                                            <Stack
                                                key={index}
                                                direction="column"
                                                spacing={1}

                                            >
                                                <Box sx={{width: '100%', marginLeft: 'auto !important', marginRight: 'auto !important'}}>
                                                    <Typography sx={{width: 'fit-content', marginLeft: 'auto !important'}} level="body-xs">{formatDate(message.timestamp, true)}</Typography>
                                                    <Card sx={{width: '100%'}} variant="outlined" >
                                                        <CardContent sx={{flexDirection: 'row', justifyContent: 'space-between', width: '100%'}}>
                                                            {message.bookingData ? (
                                                                <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}>
                                                                    <Box>
                                                                        <Typography level="title-md">
                                                                            Booking Created
                                                                        </Typography>
                                                                        <Typography level="body-sm">
                                                                            Booked in for {formatDate(message.bookingData?.booked_in, false)}
                                                                        </Typography>
                                                                    </Box>

                                                                    <Button variant="solid" color="primary" onClick={() => handleOpenBookingModal(message.bookingData?.id)}>
                                                                        Edit Booking
                                                                    </Button>
                                                                </Box>
                                                            ) : (
                                                                <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}>
                                                                    <Typography level="title-md">
                                                                        Lead Created
                                                                    </Typography>
                                                                    <Button variant="solid" color="primary" onClick={() => handleOpenLeadModal(message.leadData?.id)}>
                                                                        Edit Lead
                                                                    </Button>
                                                                </Box>
                                                            )}

                                                        </CardContent>

                                                    </Card>
                                                </Box>

                                            </Stack>
                                        )

                                    } else {
                                        const isYou = !message.sender.isCustomer;
                                        return (
                                            <Stack
                                                key={index}
                                                direction="row"
                                                spacing={2}
                                                sx={{flexDirection: isYou ? 'row-reverse' : 'row'}}
                                            >
                                                <ChatBubble variant={isYou ? 'sent' : 'received'} {...message} />
                                            </Stack>
                                        );
                                    }

                                })}
                        </Stack>
                    </Box>
                    <MessageInput
                        key={seed}
                        latestLead={lead}
                        latestLeadValue={leadValue}
                        messages={chatMessages}
                        textAreaValue={textAreaValue}
                        setTextAreaValue={setTextAreaValue}
                        onSubmit={() => addChatMessage()}
                    />
                </>
            )}
        </Sheet>


    );
}