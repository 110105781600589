import {Modal, ModalClose, ModalDialog, Slider} from "@mui/joy";
import React, {useEffect} from "react";
import Button from "@mui/joy/Button";
import {PurchaseLeads} from "../../axios/Axios";
import DialogActions from '@mui/joy/DialogActions';
import DialogContent from "@mui/joy/DialogContent";


export default function StripeBalanceTopUpModal({openState, franchise, parentCallback}) {
    const [value, setValue] = React.useState<number>(150)
    const [open, setOpen] = React.useState(openState);


    useEffect(() => {
        setOpen(openState)
    }, [openState]);

    async function purchaseLeads() {
        let url = await PurchaseLeads(franchise.location, value, false)
        if (url) {
            window.location.replace(url['payment_link']);
        }
    }

    function closeModal() {
        setOpen(false)
        parentCallback('')
    }


    const formatGBP = (amount) => {
        return new Intl.NumberFormat('en-GB', {
            style: 'currency',
            currency: 'GBP',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }).format(amount);
    };


    return (
        <>
            <Modal slotProps={{
                backdrop: {
                    sx: {
                        backdropFilter: 'none',
                        backgroundColor: 'rgb(108 108 108 / 60%)',
                        padding: '20px'
                    },
                },
            }} open={open} onClose={() => closeModal()}>
                <ModalDialog>
                    <ModalClose></ModalClose>

                    <DialogContent sx={{maxWidth: '400px'}}>
                        Top your balance up to make sure your ads don't turn off.
                    </DialogContent>
                    <Slider
                        value={value}
                        onChange={(event: Event, newValue: number | number[]) => {
                            setValue(newValue as number);
                        }}
                        min={50}
                        max={1250}
                        step={1}
                        valueLabelDisplay="on"
                        valueLabelFormat={formatGBP}
                        sx={{marginTop: 2}}
                    />

                    <DialogActions>
                        <Button color="success" onClick={() => purchaseLeads()}>
                            Click here to top up your balance with {formatGBP(value)}
                        </Button>
                    </DialogActions>

                </ModalDialog>
            </Modal>
        </>
    )


}