import * as React from 'react';
import {useEffect} from 'react';
import IconButton from '@mui/joy/IconButton';
import Table from '@mui/joy/Table';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {CallCustomerById, GetAllCalls,} from "../../axios/Axios";
import Box from "@mui/joy/Box";
import {KeyboardArrowLeft, KeyboardArrowRight, Message, PhoneCallback} from "@mui/icons-material";
import {CircularProgress} from "@mui/joy";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import {useSearchParams} from "react-router-dom";
import DebounceInput from "../../domain/DebounceInput";

function formatDate(old_date) {
    let date = new Date(old_date);

    // Check if the date is valid
    if (!isNaN(date.getTime())) {
        // Date is valid, format it
        return date.toLocaleString('en-US', {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric'
        });
    } else {
        return 'No Valid Date';
    }
}

interface Row {
    fromNumber: string;
    toNumber: string;
    duration?: number;
    callType: string;
    answerStatus: string;
    timestamp: string;
    customer?: number | null;
}


function Row(props: { row: Row}) {
    const {row} = props;

    const handleCallCustomer = () => {
        let location = localStorage.getItem('location')?.toLowerCase()
        if (row.callType == 'inbound'){
            CallCustomerById(row.fromNumber, location)
        }else{
            CallCustomerById(row.fromNumber, location)
        }

    };

    const handleMessageCustomer = () => {

    };

    return (
        <React.Fragment>
            <tr style={{ width: '100%', background: row.answerStatus === 'answered' ? 'none' : '#ff00002b'}}>
                <td>{formatDate(row.timestamp)}</td>
                <td>{row.callType === 'inbound' ? row.fromNumber : row.toNumber}</td>
                <td>{row.answerStatus.replace('_', ' ')}</td>
                <td>{row.callType}</td>
                <td>
                    <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                        <IconButton variant="solid" sx={{ml: 1, textWrap: 'nowrap'}} size="sm" color="primary"
                                    onClick={handleCallCustomer}>
                            <PhoneCallback/>
                        </IconButton>
                        <IconButton variant="solid" sx={{ml: 1, textWrap: 'nowrap'}} size="sm" color="primary"
                                    onClick={handleMessageCustomer}>
                            <Message/>
                        </IconButton>
                    </Box>
                </td>
            </tr>
        </React.Fragment>
    );
}

const mapRawDataToRow  = (data: any[]): Row[] => {
    return data.map(item => ({
        fromNumber: item.from_number,
        toNumber: item.to_number,
        duration: item.duration,
        callType: item.call_type,
        answerStatus: item.answer_status,
        timestamp: item.timestamp,
        customer: item.customer?.id ?? null,
    }));
};


function labelDisplayedRows({
                                from,
                                to,
                                count,
                            }: {
    from: number;
    to: number;
    count: number;
}) {
    return `${from}–${to} of ${count !== -1 ? count : `more than ${to}`}`;
}

export default function CallLogTable() {
    const [loadedData, setLoadingData] = React.useState(false);
    const [rows, setRows] = React.useState <Row[]>([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(15);
    const [pageCount, setPageCount] = React.useState(0);
    const [search, setSearch] = React.useState('');
    const [urlSearchParam, setUrlSearchParam] = useSearchParams();


    const getLabelDisplayedRowsTo = () => {
        if (pageCount === -1) {
            return (page + 1) * rowsPerPage;
        }
        return rowsPerPage === -1
            ? pageCount
            : Math.min(pageCount, (page + 1) * rowsPerPage);
    };

    const handleChangePage = (newPage: number) => {
        setPage(newPage);
    };

    useEffect(() => {
        if (!loadedData) {
            return
        }

        setLoadingData(false)
        const fetchData = async () => {
            let location = localStorage.getItem('location')?.toLowerCase().replace(' ', '_')
            let calls = await GetAllCalls([location], page + 1, search);
            setRows(mapRawDataToRow(calls['results']))
            setPageCount(calls['count'])

            setLoadingData(true)
        };
        fetchData()
    }, [page, search]);

    useEffect(() => {

        setLoadingData(false)

        const fetchData = async () => {
            let location = localStorage.getItem('location')?.toLowerCase().replace(' ', '_')
            let calls = await GetAllCalls([location], page + 1, search);
            setRows(mapRawDataToRow(calls['results']))
            setPageCount(calls['count'])

            setLoadingData(true)
        };
        fetchData()
    }, []);


    const handleDebounce = (value: string) => {
        setSearch(value);
    };

    return (
        <>
            <Box>
                <DebounceInput sx={{width: '15%'}} startDecorator={<SearchRoundedIcon/>}
                               placeholder="Search by any field..."
                               debounceTimeout={1000}
                               defaultValue={urlSearchParam.get("lead-id") as string}
                               handleDebounce={handleDebounce}
                />
            </Box>
            <Sheet>

                <Table
                    hoverRow
                    aria-label="collapsible table"
                    sx={{
                        height: '100%',
                        width: '100%',
                        '& > thead > tr > th:nth-child(n + 3), & > tbody > tr > td:nth-child(n + 3)':
                            {textAlign: 'right'},
                        '& > tbody > tr:nth-child(odd) > td, & > tbody > tr:nth-child(odd) > th[scope="row"]':
                            {
                                borderBottom: 0,
                            },
                    }}
                >
                    <thead>
                    <tr style={{width: '100%'}}>
                        <th style={{width: '300px'}}>Date</th>
                        <th>Customer Number</th>
                        <th>Status</th>
                        <th>Call Type</th>
                        <th aria-label="last"></th>
                    </tr>
                    </thead>
                    {loadedData ?
                        <>
                            <tbody>
                            {rows.map((row, index) => (
                                <Row key={index} row={row}/>
                            ))}
                            </tbody>
                            <tfoot>
                            <tr>
                                <td colSpan={5}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: 2,
                                            justifyContent: 'flex-end',
                                        }}
                                    >
                                        <Typography textAlign="center" sx={{minWidth: 80}}>
                                            {labelDisplayedRows({
                                                from: pageCount === 0 ? 0 : page * rowsPerPage + 1,
                                                to: getLabelDisplayedRowsTo(),
                                                count: pageCount === -1 ? -1 : pageCount,
                                            })}
                                        </Typography>
                                        <Box sx={{display: 'flex', gap: 1}}>
                                            <IconButton
                                                size="sm"
                                                color="neutral"
                                                variant="outlined"
                                                disabled={page === 0}
                                                onClick={() => handleChangePage(page - 1)}
                                                sx={{bgcolor: 'background.surface'}}
                                            >
                                                <KeyboardArrowLeft/>
                                            </IconButton>
                                            <IconButton
                                                size="sm"
                                                color="neutral"
                                                variant="outlined"
                                                disabled={
                                                    pageCount !== -1
                                                        ? page >= Math.ceil(pageCount / rowsPerPage) - 1
                                                        : false
                                                }
                                                onClick={() => handleChangePage(page + 1)}
                                                sx={{bgcolor: 'background.surface'}}
                                            >
                                                <KeyboardArrowRight/>
                                            </IconButton>
                                        </Box>
                                    </Box>
                                </td>
                            </tr>
                            </tfoot>
                        </>
                        :
                        <tbody>
                        <tr style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                            position: 'absolute',
                            marginTop: '50px',
                        }}>
                            <CircularProgress
                                size="lg"
                            />
                        </tr>
                        </tbody>
                    }
                </Table>

            </Sheet>

        </>


    );
}