import * as React from 'react';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import FormControl from '@mui/joy/FormControl';
import Textarea from '@mui/joy/Textarea';
import {Stack} from '@mui/joy';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import {BookmarkAdd} from "@mui/icons-material";
import BookingsModal from "../../modals/BookingsModal";

export type MessageInputProps = {
    textAreaValue: string;
    messages: MessageProps[];
    setTextAreaValue: (value: string) => void;
    onSubmit: () => void;
    latestLead: Lead;
    latestLeadValue: ServiceValue;
};

export default function MessageInput(props: MessageInputProps) {
    const {textAreaValue, setTextAreaValue, onSubmit} = props;
    const textAreaRef = React.useRef<HTMLDivElement>(null);
    const [openBookingModal, setOpenBookingModal] = React.useState(false);
    const handleOpenBookingModal = () => {
        setOpenBookingModal(true)
    };
    const handleClick = () => {
        if (textAreaValue.trim() !== '') {
            onSubmit();
            setTextAreaValue('');
        }
    };
    let handleCallback = () => {
        setOpenBookingModal(false)
    };

    return (
        <Box sx={{px: 2, pb: 3}}>
            <BookingsModal key={`bookings-modal-${openBookingModal}-${props.latestLead?.id || 'default'}`}
                           openState={openBookingModal}
                           newBooking={null}
                           newBookingValue={props.latestLeadValue}
                           bookingId={-1} parentCallback={handleCallback}/>
            <FormControl>
                <Textarea
                    disabled={props.messages.length == 0 || props.messages[0].sender.name == ''}
                    placeholder="Type something here…"
                    aria-label="Message"
                    ref={textAreaRef}
                    onChange={(e) => {
                        setTextAreaValue(e.target.value);
                    }}
                    value={textAreaValue}
                    minRows={3}
                    maxRows={10}
                    endDecorator={
                        <Stack
                            direction="row"
                            sx={{
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                flexGrow: 1,
                                py: 1,
                                pr: 1,
                                borderTop: '1px solid',
                                borderColor: 'divider',
                            }}
                        >
                            <div>

                            </div>
                            <div>


                                <Button
                                    size="sm"
                                    color="primary"
                                    sx={{alignSelf: 'center', borderRadius: 'sm'}}
                                    endDecorator={<SendRoundedIcon/>}
                                    onClick={handleClick}
                                >
                                    Send
                                </Button>
                            </div>
                        </Stack>
                    }
                    onKeyDown={(event) => {
                        if (event.key === 'Enter' && (event.metaKey || event.ctrlKey)) {
                            handleClick();
                        }
                    }}
                    sx={{
                        '& textarea:first-of-type': {
                            minHeight: 72,
                        },
                    }}
                />
            </FormControl>
        </Box>
    );
}