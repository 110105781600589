import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import App from "./components/App";

if (
    window.location.protocol !== 'https:' &&
    window.location.hostname !== 'localhost' &&
    !/^(\d{1,3}\.){3}\d{1,3}$/.test(window.location.hostname)
) {
    window.location.href = 'https://' + window.location.href.split('//')[1];
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <>
        <App/>
    </>
);


reportWebVitals();
