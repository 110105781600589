import axios from "axios";

const development = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

export default function isDev() {
    return development;
}

let backend = 'https://api.yeticlean.co.uk/'

if (isDev()) {
    backend = 'http://127.0.0.1:8000/'
}

export const AxiosPublic = axios.create({
    baseURL: backend,
    headers: {
        "Content-Type": "application/json",
    }
});

export const AxiosPrivate = axios.create({
    baseURL: backend,
    headers: {
        "Content-Type": "application/json",
    },
    withCredentials: true,
});

AxiosPrivate.interceptors.response.use(
    function (initialResponse) {
        return initialResponse;
    },
    async function (error) {
        if (error.response && error.response.status === 401) {
            try {
                const response = await VerifyLoggedIn();
                if (response.status === 200) {
                    return axios.request(error.config);
                } else {
                    return Promise.reject("No Longer Logged In!");
                }
            } catch (err) {
                return Promise.reject("No Longer Logged In!");
            }
        } else {
            return Promise.reject(error);
        }
    }
);


export function LoginUser(user) {
    return AxiosPrivate({
        url: backend + 'accounts/login/',
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        data: user,
    })
}

export function LogoutUser() {
    return AxiosPublic({
        url: backend + 'accounts/logout/',
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        }
    })
}

export function GetUser() {
    return AxiosPrivate({
        url: backend + 'accounts/user/',
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        }
    })
}

export function VerifyLoggedIn() {
    return AxiosPublic({
        url: backend + 'accounts/token/refresh/',
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        withCredentials: true,

    })
}

export function GetAllSMSReplies(locations) {
    let params = {};

    if (locations && locations.length > 0) {
        const locationString = locations.join(',');
        params = {
            location: locationString
        };
    }
    return AxiosPrivate({
        url: backend + 'marketing/all-sms-responses/',
        method: "GET",
        params: params,
        headers: {
            "Content-Type": "application/json",
        }
    })
}

export function GetAllSMS(locations) {
    let params = {};

    if (locations && locations.length > 0) {
        const locationString = locations.join(',');
        params = {
            location: locationString
        };
    }
    return AxiosPrivate({
        url: backend + 'marketing/all-sms/',
        method: "GET",
        params: params,
        headers: {
            "Content-Type": "application/json",
        }
    })
}

export function UpdateReadStatus(phone) {
    return AxiosPrivate({
        url: backend + 'marketing/update-read-status/',
        method: "POST",
        data: {
            phone_number: phone
        },
        headers: {
            "Content-Type": "application/json",
        }
    });
}

export function PostSMSTextResponse(messageProps) {
    return AxiosPrivate({
        url: backend + 'marketing/send-sms-responses/',
        method: "POST",
        data: {
            message_props: messageProps
        },
        headers: {
            "Content-Type": "application/json",
        }
    });
}


export function GetAllFranchises() {
    return AxiosPrivate({
        url: backend + 'franchise/all/',
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        }
    })
}

export function GetAllFranchisePostcodes(franchise) {
    franchise = franchise.replace(' ', '_')

    return AxiosPrivate({
        url: backend + 'franchise/franchise-postcodes/' + franchise,
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        }
    })
}

export function GetFranchiseByName(name) {
    name = name.replace(' ', '_')
    return new Promise((resolve, reject) => {
        return AxiosPrivate({

            url: backend + 'franchise/location/' + name,
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            }

        }).then((data) => {
            resolve(data.data)
        }).catch(error => {
            reject(error)
        });
    });
}

export function GetFranchiseNotifications(name) {
    name = name.replace(' ', '_')
    return new Promise((resolve, reject) => {
        return AxiosPrivate({

            url: backend + 'franchise/notifications/' + name,
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            }

        }).then((data) => {
            resolve(data.data)
        }).catch(error => {
            reject(error)
        });
    });
}


export function SendRotaEmail(email, locations) {
    let params = {};

    if (locations && locations.length > 0) {
        const locationString = locations.join(',');
        params = {
            locations: locationString
        };
    }
    return AxiosPrivate({
        url: backend + 'marketing/send-rota/' + email + '/',
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        params: params,

    })
}


export function GetToggledAds(locationName) {

    return new Promise((resolve, reject) => {

        return AxiosPrivate({
            url: backend + 'franchise/ads/' + locationName + '/',
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            }
        }).then((data) => {
            resolve(data.data)
        }).catch(error => {
            reject(error)
        });
    });
}

export function SetFranchiseAdStatus(location, ad_type, status, toggleOffReason = '') {
    let params = {};
    params.ad_type = ad_type
    params.status = status

    if (toggleOffReason) {
        params.toggleOffReason = toggleOffReason
    }
    return AxiosPrivate({
        url: backend + 'franchise/ads/' + location + '/',
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        data: params,
    });
}

export function CreateStripeAccountForFranchise(franchise) {
    return new Promise((resolve, reject) => {
        return AxiosPrivate({
            url: backend + 'invoices/stripe-create-account/' + franchise,
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            }

        }).then((data) => {
            resolve(data.data)
        }).catch(error => {
            reject(error)
        });
    });
}

export function CreateStripePaymentMethod(franchise, amount) {
    return new Promise((resolve, reject) => {
        return AxiosPrivate({
            url: backend + 'invoices/stripe-store-payment-method/' + franchise + '/' + amount,
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            }

        }).then((data) => {
            resolve(data.data)
        }).catch(error => {
            reject(error)
        });
    });
}

export function PurchaseLeads(franchise, cost, turningLeadsOn) {
    return new Promise((resolve, reject) => {
        return AxiosPrivate({
            url: backend + 'invoices/stripe-create-leads-invoice/' + franchise + '/' + cost,
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            params: {'turning_leads_on': turningLeadsOn}

        }).then((data) => {
            resolve(data.data)
        }).catch(error => {
            reject(error)
        });
    });
}


export function IsLastPaymentSuccess(locationName) {
    return new Promise((resolve, reject) => {
        return AxiosPrivate({
            url: backend + 'invoices/stripe-last-payment-success/' + locationName,
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            }
        }).then((data) => {
            resolve(data.data)
        }).catch(error => {
            reject(error)
        });
    });
}

export function GetStripeInvoices(locationName) {
    return new Promise((resolve, reject) => {
        return AxiosPrivate({
            url: backend + 'invoices/stripe-invoices/' + locationName,
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            }
        }).then((data) => {
            resolve(data.data)
        }).catch(error => {
            reject(error)
        });
    });
}

export function GetStripePayouts(locationName) {
    return new Promise((resolve, reject) => {
        return AxiosPrivate({
            url: backend + 'invoices/stripe-payouts/' + locationName,
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            }
        }).then((data) => {
            resolve(data.data)
        }).catch(error => {
            reject(error)
        });
    });
}

export function PostRecurringStatus(locationName, value) {
    return new Promise((resolve, reject) => {
        return AxiosPrivate({

            url: backend + 'franchise/update-recurring-status/' + locationName + '/',
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            params: {'recurring_leads_purchase': value},
        }).then((data) => {
            resolve(data.data)
        }).catch(error => {
            reject(error)
        });
    });
}

export function PostFranchiseSettings(locationName, franchise, dates, notifications) {
    return new Promise((resolve, reject) => {
        return AxiosPrivate({
            url: backend + 'franchise/update-settings/' + locationName + '/',
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            data: {
                'franchise': franchise,
                'dates': dates,
                'notifications': notifications
            },
        }).then((data) => {
            resolve(data.data)
        }).catch(error => {
            reject(error)
        });
    });
}


export function GetAllDocuments() {
    return new Promise((resolve, reject) => {
        return AxiosPrivate({
            url: backend + 'documents/all/',
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            }
        }).then((data) => {
            resolve(data.data)
        }).catch(error => {
            reject(error)
        });
    });
}

export function GetDocumentByCourseSlug(slug) {
    return new Promise((resolve, reject) => {
        return AxiosPrivate({
            url: backend + 'documents/course/documents/' + slug,
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            }
        }).then((data) => {
            resolve(data.data)
        }).catch(error => {
            reject(error)
        });
    });
}

export function GetAllCourses() {
    return new Promise((resolve, reject) => {
        return AxiosPrivate({
            url: backend + 'documents/course/all/',
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            }
        }).then((data) => {
            resolve(data.data)
        }).catch(error => {
            reject(error)
        });
    });
}

export function GetCourseBySlug(slug) {
    return new Promise((resolve, reject) => {
        return AxiosPrivate({
            url: backend + 'documents/course/' + slug,
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            }
        }).then((data) => {
            resolve(data.data)
        }).catch(error => {
            reject(error)
        });
    });
}


export function CallCustomerById(customer_id, franchise_id) {
    return new Promise((resolve, reject) => {
        AxiosPublic({
            url: backend + `marketing/outbound-call-customer/${customer_id}/${franchise_id}/`,
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            withCredentials: true,
        }).then((data) => {
            resolve(data.data);
        }).catch(error => {
            reject(error);
        });
    });
}

export function GetAllCalls(locations, page, search) {
    let params = {};

    if (locations && locations.length > 0) {
        const locationString = locations.join(',');
        params = {
            location: locationString
        };
    }

    if (search) {
        params.search = search;
    }

    params.page = page
    return new Promise((resolve, reject) => {
        AxiosPrivate({
            url: backend + `marketing/all-calls/`,
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
            params: params,
            withCredentials: true,

        }).then((data) => {
            resolve(data.data);
        }).catch(error => {
            reject(error);
        });
    });
}
export function GetAllAdBalanceChanges(franchise, page) {
    let params = {};

    params.page = page
    return new Promise((resolve, reject) => {
        AxiosPrivate({
            url: backend + `leads/balance-changes/` + franchise,
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
            params: params,
            withCredentials: true,
        }).then((data) => {
            resolve(data.data);
        }).catch(error => {
            reject(error);
        });
    });
}
