import React, {useState} from "react";
import {Box, Button, Card, Typography} from "@mui/joy";
import {CreateStripeAccountForFranchise} from "../../axios/Axios";

export default function Stripe({franchise}) {
    const [accountCreatePending, setAccountCreatePending] = useState(false);
    const [error, setError] = useState(false);
    const [connectedAccountId, setConnectedAccountId] = useState();

    const handleLinkClick = async () => {
        let location = localStorage.getItem('location')?.toLowerCase().replace(' ', '_')
        setAccountCreatePending(true)
        let link = await CreateStripeAccountForFranchise(location)
        if (link) {
            window.location.replace(link['url']);
        }
    };
    return (
        <>
            <Box sx={{height: '80vh', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <Card sx={{minWidth: '300px'}} size="lg">
                    <Typography>In order to use this feature, you need to link {franchise.location} with
                        Stripe.</Typography>
                    <Button loading={accountCreatePending} onClick={handleLinkClick}>Click here to link</Button>
                </Card>
            </Box>

        </>
    );
}