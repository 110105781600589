import * as React from 'react';
import {useEffect} from 'react';
import {Breadcrumbs, CircularProgress, CssBaseline, CssVarsProvider, Link} from "@mui/joy";
import Box from "@mui/joy/Box";
import Header from "../../dashboard-components/Header";
import SideNav from "../../dashboard-components/SideNav";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import Typography from "@mui/joy/Typography";
import ChatsPane from "./ChatsPane";
import MessagesPane from "./MessagesPane";
import Sheet from "@mui/joy/Sheet";
import {GetAllSMS, GetAllSMSReplies, UpdateReadStatus} from "../../../axios/Axios";
import {GetAllBookings, GetAllLeads} from "../../../axios/Bookings-Axios";

const mapToMessage = (smsItem, userProps, lead?, booking?): MessageProps => {
    return {
        id: smsItem.timestamp,
        content: smsItem.message,
        timestamp: smsItem.timestamp,
        unread: !smsItem.read_status,
        sender: userProps,
        leadData: lead,
        bookingData: booking
    };
};

const mapToChatProps = (data, isCustomer, sender) => {
    const userProps = {
        id: data.customer.id,
        name: data.customer.full_name,
        phone: data.customer.phone,
        isCustomer: isCustomer,
        username: sender
    };

    const messageProps = {
        id: String(data.id),
        content: data.message,
        timestamp: data.timestamp,
        unread: !data.read_status,
        sender: userProps
    };

    const chatProps = {
        id: String(data.timestamp),
        sender: userProps,
        messages: [messageProps]
    };

    return chatProps;
};


export default function Messages() {
    const [selectedChat, setSelectedChat] = React.useState<ChatProps>({
        id: "", messages: [], sender: {
            id: "",
            name: "",
            phone: "",
            isCustomer: true,
            username: ""
        }
    });
    const [chats, setChats] = React.useState<ChatProps[]>([]);
    const [loadedData, setLoadingData] = React.useState(false);

    const latestMessage = (messages) => {
        if (messages.filter((message) => message.sender.isCustomer).length > 0) {
            return messages.filter((message) => message.sender.isCustomer).reduce((latest, current) =>
                new Date(current.timestamp).getTime() > new Date(latest.timestamp).getTime() ? current : latest
            );
        }
        return null
    }


    useEffect(() => {
        if (selectedChat.id != '' && latestMessage(selectedChat.messages) && latestMessage(selectedChat.messages).unread) {
            let readChat = UpdateReadStatus(selectedChat.sender.phone);
            if (selectedChat) {
                setSelectedChat((prevChat) => ({
                    ...prevChat,
                    messages: prevChat.messages.map((message) => ({
                        ...message,
                        unread: false,
                    })),
                }));
                setChats((prevChats) =>
                    prevChats.map((chat) => {
                        if (chat.id === selectedChat.id) {
                            return {
                                ...chat,
                                messages: chat.messages.map((message) => ({
                                    ...message,
                                    unread: false,
                                })),
                            };
                        }
                        return chat;
                    }))

            }
        }
    }, [selectedChat.sender.phone])
    useEffect(() => {
        setLoadingData(false)

        const fetchData = async () => {

            let location = localStorage.getItem('location')?.toLowerCase().replace(' ', '_')


            let sms = await GetAllSMS([location]);

            let chats: ChatProps[] = [];
            const findChatByPhone = (phone: string) => chats.find(chat => chat.sender.phone === phone);
            sms.data.forEach((smsItem) => {
                const existingChat = findChatByPhone(smsItem.customer.phone);
                if (existingChat) {
                    existingChat.messages.push(mapToMessage(smsItem, {
                        name: smsItem.customer.full_name,
                        phone: smsItem.customer.phone,
                        isCustomer: true,
                        username: smsItem.customer.full_name
                    }));
                } else {
                    chats.push(mapToChatProps(smsItem, true, smsItem.customer.full_name));
                }
            });

            let smsReplies = await GetAllSMSReplies([location]);
            smsReplies.data.forEach((smsItem) => {
                const existingChat = findChatByPhone(smsItem.customer.phone);
                if (existingChat) {
                    existingChat.messages.push(mapToMessage(smsItem, {
                        name: smsItem.user.username,
                        phone: smsItem.customer.phone,
                        isCustomer: false,
                        username: smsItem.user.username
                    }));
                } else {
                    chats.push(mapToChatProps(smsItem, false, smsItem.user.username));
                }
            })


            const phoneNumbers = [...new Set(sms.data.map(item => item.customer.phone))];
            let bookings = await GetAllBookings([location], -1, '', undefined, undefined, undefined, undefined, undefined, phoneNumbers);
            let leads = await GetAllLeads([location], -1, '', undefined, undefined, phoneNumbers)

            leads.forEach((lead) => {
                const existingChat = findChatByPhone(lead.customer.phone)
                lead.timestamp = lead.created_at
                if (existingChat?.messages.length > 0) {
                    existingChat.messages.push(mapToMessage(lead, {
                        name: lead.customer.full_name,
                        phone: lead.customer.phone,
                        isCustomer: false,
                        username: lead.customer.full_name
                    }, lead));
                }
            })

            bookings.forEach((booking) => {
                const existingChat = findChatByPhone(booking.customer.phone)
                booking.timestamp = booking.created_at
                if (existingChat) {
                    existingChat.messages.push(mapToMessage(booking, {
                        name: booking.customer.full_name,
                        phone: booking.customer.phone,
                        isCustomer: false,
                        username: booking.customer.full_name
                    }, null, booking));
                }
            })


            setChats(chats)
            if (chats.length > 0) {
                setSelectedChat(chats[0])
            }
            setLoadingData(true)
        };
        fetchData()
    }, []);

    return (
        <CssVarsProvider disableTransitionOnChange>
            <CssBaseline/>
            <Box sx={{display: 'flex', minHeight: '90dvh'}}>
                <Header/>
                <SideNav/>
                <Box
                    component="main"
                    className="MainContent"
                    sx={{
                        px: {xs: 2, md: 6},
                        pt: {
                            xs: 'calc(12px + var(--Header-height))',
                            sm: 'calc(12px + var(--Header-height))',
                            md: 3,
                        },
                        pb: {xs: 2, sm: 2, md: 3},
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth: 0,
                        height: '90dvh',
                        gap: 1,
                    }}
                >
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <Breadcrumbs
                            size="sm"
                            aria-label="breadcrumbs"
                            separator={<ChevronRightRoundedIcon/>}
                            sx={{pl: 0}}
                        >
                            <Link
                                underline="none"
                                color="neutral"
                                href="/dashboard"
                                aria-label="Home"
                            >
                                <HomeRoundedIcon/>
                            </Link>
                            <Link
                                underline="hover"
                                color="neutral"
                                href="/dashboard"
                                fontSize={12}
                                fontWeight={500}
                            >
                                Messages
                            </Link>
                            <Typography color="primary" fontWeight={500} fontSize={12}>
                                Messages
                            </Typography>
                        </Breadcrumbs>
                    </Box>
                    {!loadedData ? (
                        <Box sx={{
                            display: 'flex',
                            gap: 2,
                            alignItems: 'center',
                            flexWrap: 'wrap',
                            width: '100%',
                            height: '100%',
                            justifyContent: 'center'
                        }}>
                            <CircularProgress
                                size="lg"
                            />
                        </Box>
                    ) : (
                        <Box
                            sx={{
                                display: 'flex',
                                mb: 1,
                                gap: 1,
                                flexDirection: {xs: 'column', sm: 'row'},
                                alignItems: {xs: 'start', sm: 'center'},
                                flexWrap: 'wrap',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Sheet
                                sx={{
                                    flex: 1,
                                    width: '100%',
                                    mx: 'auto',
                                    pt: {xs: 'var(--Header-height)', md: 0},
                                    display: 'grid',
                                    gridTemplateColumns: {
                                        xs: '1fr',
                                        sm: 'minmax(min-content, min(30%, 400px)) 1fr',
                                    },
                                }}
                            >
                                <Sheet
                                    sx={{
                                        position: {xs: 'fixed', sm: 'sticky'},
                                        transform: {
                                            xs: 'translateX(calc(100% * (var(--MessagesPane.tsx-slideIn, 0) - 1)))',
                                            sm: 'none',
                                        },
                                        transition: 'transform 0.4s, width 0.4s',
                                        zIndex: 100,
                                        width: '100%',
                                        top: 52,
                                    }}
                                >
                                    <ChatsPane
                                        chats={chats}
                                        selectedChatId={selectedChat.sender.id}
                                        setSelectedChat={setSelectedChat}
                                    />
                                </Sheet>
                                <MessagesPane chat={selectedChat}/>
                            </Sheet>
                        </Box>
                    )}

                </Box>
            </Box>
        </CssVarsProvider>
    )
}