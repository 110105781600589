import * as React from 'react';
import Stack from '@mui/joy/Stack';
import Sheet from '@mui/joy/Sheet';
import Typography from '@mui/joy/Typography';
import {Box, Chip, IconButton, Input} from '@mui/joy';
import List from '@mui/joy/List';
import EditNoteRoundedIcon from '@mui/icons-material/EditNoteRounded';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import {toggleMessagesPane} from "./MessageUtils";
import ChatListItem from "./ChatListItem";
import { v4 as uuidv4 } from 'uuid';

type ChatsPaneProps = {
    chats: ChatProps[];
    setSelectedChat: (chat: ChatProps) => void;
    selectedChatId: string;
};

export default function ChatsPane(props: ChatsPaneProps) {
    const {chats, setSelectedChat, selectedChatId} = props;
    const [searchTerm, setSearchTerm] = React.useState("");
    return (
        <Sheet
            sx={{
                borderRight: '1px solid',
                borderColor: 'divider',
                height: {sm: 'calc(90dvh - var(--Header-height))', md: '90dvh'},
                overflowY: 'auto',
            }}
        >
            <Stack
                direction="row"
                spacing={1}
                sx={{alignItems: 'center', justifyContent: 'space-between', p: 2, pb: 1.5}}
            >
                <Typography
                    component="h1"
                    endDecorator={
                        <Chip
                            variant="soft"
                            color="primary"
                            size="md"
                            slotProps={{root: {component: 'span'}}}
                        >
                            {chats.reduce((totalUnread, chat) => {
                                const unreadCount = chat.messages.filter((message) => message.sender.isCustomer && message.unread).length;
                                return totalUnread + unreadCount;
                            }, 0)}
                        </Chip>
                    }
                    sx={{fontSize: {xs: 'md', md: 'lg'}, fontWeight: 'lg', mr: 'auto'}}
                >
                    Messages
                </Typography>
                <IconButton
                    variant="plain"
                    aria-label="edit"
                    color="neutral"
                    size="sm"
                    sx={{display: {xs: 'none', sm: 'unset'}}}
                >
                    <EditNoteRoundedIcon/>
                </IconButton>
                <IconButton
                    variant="plain"
                    aria-label="edit"
                    color="neutral"
                    size="sm"
                    onClick={() => {
                        toggleMessagesPane();
                    }}
                    sx={{display: {sm: 'none'}}}
                >
                    <CloseRoundedIcon/>
                </IconButton>
            </Stack>
            <Box sx={{px: 2, pb: 1.5}}>
                <Input
                    size="sm"
                    startDecorator={<SearchRoundedIcon/>}
                    placeholder="Search"
                    aria-label="Search"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value.toLowerCase())}
                />
            </Box>
            <List
                sx={{
                    py: 0,
                    '--ListItem-paddingY': '0.75rem',
                    '--ListItem-paddingX': '1rem',
                    maxWidth: '450px',
                }}
            >
                {chats.sort((a, b) => new Date(b.messages[0].timestamp).getTime() - new Date(a.messages[0].timestamp).getTime()).map((chat) => (
                    <ChatListItem
                        key={chat.id}
                        {...chat}
                        setSelectedChat={setSelectedChat}
                        selectedChatId={selectedChatId}
                    />
                ))}
            </List>
        </Sheet>
    );
}