import React, {useEffect, useState} from "react";
import {Breadcrumbs, Card, CardContent, CssBaseline, Link, Tab, tabClasses, TabList, TabPanel, Tabs} from "@mui/joy";
import Header from "../dashboard-components/Header";
import SideNav from "../dashboard-components/SideNav";
import Box from "@mui/joy/Box";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import Typography from "@mui/joy/Typography";
import {CssVarsProvider} from "@mui/joy/styles";
import {GetAllCourses} from "../../axios/Axios";
import Grid from "@mui/joy/Grid";

export default function KnowledgeBase() {
    const [index, setIndex] = React.useState(0);
    const [loadingData, setLoadingData] = useState(true);
    const [courses, setCourses] = React.useState<Course[]>();

    useEffect(() => {
        setLoadingData(true)

        const getCourses = async () => {
            let courses = await GetAllCourses();
            setCourses(courses)
        };

        getCourses();
        setLoadingData(false)


    }, [])

    return (
        <CssVarsProvider disableTransitionOnChange>
            <CssBaseline/>
            <Box sx={{display: 'flex'}}>
                <Header/>
                <SideNav/>
                <Box
                    component="main"
                    className="MainContent"
                    sx={{
                        px: {xs: 2, md: 6},
                        pt: {
                            xs: 'calc(12px + var(--Header-height))',
                            sm: 'calc(12px + var(--Header-height))',
                            md: 3,
                        },
                        pb: {xs: 2, sm: 2, md: 3},
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth: 0,
                        gap: 1,
                        height: 'fit-content'
                    }}
                >
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <Breadcrumbs
                            size="sm"
                            aria-label="breadcrumbs"
                            separator={<ChevronRightRoundedIcon/>}
                            sx={{pl: 0}}
                        >
                            <Link
                                underline="none"
                                color="neutral"
                                href="/dashboard"
                                aria-label="Home"
                            >
                                <HomeRoundedIcon/>
                            </Link>
                            <Link
                                underline="hover"
                                color="neutral"
                                href="/dashboard"
                                fontSize={12}
                                fontWeight={500}
                            >
                                Dashboard
                            </Link>
                            <Typography color="primary" fontWeight={500} fontSize={12}>
                                Knowledge Base
                            </Typography>
                        </Breadcrumbs>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            mb: 1,
                            gap: 1,
                            flexDirection: {xs: 'column', sm: 'row'},
                            flexWrap: 'wrap',
                            height: '100%'
                        }}
                    >
                        <Box sx={{width: '100%', mb: 2}}>
                            <Typography level="h2" component="h1">
                                Knowledge Base
                            </Typography>
                        </Box>
                        <Box sx={{flexGrow: 1, m: -2, overflowX: 'hidden'}}>
                            <Tabs
                                aria-label="Pipeline"
                                value={index}
                                onChange={(event, value) => setIndex(value as number)}
                            >
                                <TabList
                                    sx={{
                                        pt: 1,
                                        [`&& .${tabClasses.root}`]: {
                                            flex: 'initial',
                                            bgcolor: 'transparent',
                                            '&:hover': {
                                                bgcolor: 'transparent',
                                            },
                                            [`&.${tabClasses.selected}`]: {
                                                color: 'primary.plainColor',
                                                '&::after': {
                                                    height: 2,
                                                    borderTopLeftRadius: 3,
                                                    borderTopRightRadius: 3,
                                                    bgcolor: 'primary.500',
                                                },
                                            },
                                        },
                                    }}
                                >
                                    <Tab indicatorInset>
                                        Courses
                                    </Tab>
                                </TabList>
                                <Box
                                    sx={(theme) => ({
                                        '--bg': theme.vars.palette.background.surface,
                                        background: 'var(--bg)',
                                        boxShadow: '0 0 0 100vmax var(--bg)',
                                        clipPath: 'inset(0 -100vmax)',
                                    })}
                                >
                                    <TabPanel value={0}>

                                        <Grid container spacing={1} sx={{mt: 2, maxWidth: '750px'}}>
                                            {courses?.map((course) => (
                                                <Grid item xs={12} sm={6} key={course.slug}>
                                                    <Card sx={{width: '100%', height: '100%', maxWidth: '350px'}}
                                                          variant="outlined">
                                                        <img
                                                            src={course.thumbnail}
                                                            alt={course.title}
                                                            loading="lazy"
                                                            style={{
                                                                maxWidth: '100%',
                                                                maxHeight: '200px',
                                                                objectFit: 'cover',
                                                                display: 'block'
                                                            }}
                                                        />
                                                        <CardContent>

                                                            <Link
                                                                overlay
                                                                underline="none"
                                                                href={`/dashboard/knowledge-base/${course.slug}`}
                                                                sx={{color: 'text.tertiary'}}
                                                            >
                                                                <Typography level="title-lg">{course.title}</Typography>
                                                            </Link>
                                                            <Typography
                                                                level="body-sm">{course.description}</Typography>
                                                        </CardContent>
                                                    </Card>
                                                </Grid>
                                            ))}
                                        </Grid>

                                    </TabPanel>
                                </Box>
                            </Tabs>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </CssVarsProvider>
    )
}