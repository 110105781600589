import {DialogTitle, Modal, ModalClose, ModalDialog} from "@mui/joy";
import React, {useEffect} from "react";
import Input from "@mui/joy/Input";
import Button from "@mui/joy/Button";
import Typography from "@mui/joy/Typography";
import {SendRotaEmail} from "../../axios/Axios";

export default function RotaModal({openState, parentCallback}) {
    const [email, setEmail] = React.useState("");
    const [open, setOpen] = React.useState(openState);
    const [location, setLocation] = React.useState(localStorage.getItem('location')?.toLowerCase().replace(' ', '_'));

    function closeModal() {
        setOpen(false)
        parentCallback()
    }

    function sendEmail() {
        const sendEmail = async () => {
            const emailResponse = await SendRotaEmail(email, [location]);
        };
        sendEmail()
        closeModal()
    }

    useEffect(() => {
        setOpen(openState)
    }, [openState]);

    return (
        <>
            <Modal slotProps={{
                backdrop: {
                    sx: {
                        backdropFilter: 'none',
                        backgroundColor: 'rgb(108 108 108 / 60%)',
                        padding: '10px'
                    },
                },
            }} open={open} onClose={() => closeModal()}>
                <ModalDialog>
                    <ModalClose></ModalClose>
                    <DialogTitle>Sending Rota
                        For {localStorage.getItem('location')}</DialogTitle>
                    <Typography level="title-md">Enter Email</Typography>
                    <Input onChange={(event) => setEmail(event.target.value)} placeholder={"Email Address"}></Input>
                    <Button onClick={() => {
                        sendEmail()
                    }}>Send Rota</Button>
                </ModalDialog>
            </Modal>
        </>
    )

}