import * as React from 'react';
import {useEffect, useState} from 'react';
import {
    Breadcrumbs,
    Card,
    Chip,
    CircularProgress,
    CssBaseline,
    CssVarsProvider,
    Link,
    Radio,
    RadioGroup, Tab, TabPanel, TabList, Tabs, CardOverflow, Switch
} from "@mui/joy";
import Box from "@mui/joy/Box";
import Header from "../dashboard-components/Header";
import SideNav from "../dashboard-components/SideNav";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import Typography from "@mui/joy/Typography";
import {GetToggledAds, IsLastPaymentSuccess, PostRecurringStatus, SetFranchiseAdStatus} from "../../axios/Axios";
import {toast, ToastContainer} from "react-toastify";
import Stripe from "../dashboard-components/Stripe";
import StripeLeadPaymentModal from "../modals/StripeLeadPaymentModal";
import Button from "@mui/joy/Button";
import {AdsClickOutlined, InsertLink, PaymentOutlined} from "@mui/icons-material";
import StripeBalanceTopUpModal from "../modals/StripeBalanceTopUpModal";
import Tooltip from "@mui/joy/Tooltip";
import PastPaymentsTable from "../tables/PastPaymentsTable";
import  { tabClasses } from '@mui/joy/Tab';
import FormLabel from "@mui/joy/FormLabel";
import FormControl from "@mui/joy/FormControl";
import Divider from "@mui/joy/Divider";

interface Franchise {
    location: string;
    gutter_ads_status: string;
    roof_ads_status: string;
    stripe_account_linked: boolean;
    recurring_leads_purchase: boolean;
    lead_balance: number;
    payment_invoice_url: string;
    stripe_account_id: string;
    stripe_customer_account_id: string;
    stripe_customer_payment_method_linked: boolean;
}

export default function LeadGeneration() {
    const [franchise, setFranchise] = useState<Franchise>({
        stripe_account_linked: false,
        stripe_customer_payment_method_linked: false,
        recurring_leads_purchase: false,
        lead_balance: 0,
        stripe_account_id: '',
        location: '',
        stripe_customer_account_id: '',
        gutter_ads_status: '',
        roof_ads_status: '',
        payment_invoice_url: '',
    });

    let location = localStorage.getItem('location')?.toLowerCase().replace(' ', '_')
    const [loadedData, setLoadingData] = React.useState(true);
    const [selectedAds, setSelectAds] = React.useState('residential_ads');
    const [toggleOffReason, setToggleOffReason] = React.useState<String>('');
    const [selectedAdsToggle, setSelectAdsToggle] = React.useState<String>('');
    const [openStripeModal, setOpenStripeModal] = React.useState<boolean>(false);
    const [openBalanceTopUpModal, setOpenBalanceTopUpModal] = React.useState<boolean>(false);
    const [lastPaymentSuccess, setLastPaymentSuccess] = useState<boolean>(true);
    const [paymentLink, setPaymentLink] = useState<string>('');
    const [index, setIndex] = React.useState(0);


    useEffect(() => {
        const loadIsPaymentSuccess = async () => {
            let location = localStorage.getItem('location')?.toLowerCase().replace(' ', '_')
            let success = await IsLastPaymentSuccess(location)
            setLastPaymentSuccess(success['success'])
            setPaymentLink(success['failed_payment_invoice_url'])
        }
        loadIsPaymentSuccess()
    }, [])

    function createPaymentLink() {
        window.location.replace(paymentLink);
    }

    useEffect(() => {

        const getToggledAds = async () => {
            let data = await GetToggledAds(location);
            setFranchise(data)
            setLoadingData(false)
        };
        getToggledAds();

    }, [])


    function handleCallback(field, on, toggleOffReason?) {
        setOpenStripeModal(false)
        setOpenBalanceTopUpModal(false)
        if (field != '') {
            if (on) {
                SetFranchiseAdStatus(location, field, 'Pending')
                if (field == 'roof_clean') {
                    setFranchise(prevFranchise => ({
                        ...prevFranchise,
                        roof_ads_status: 'Pending'
                    }));
                    setSelectAdsToggle('Pending')
                } else if (field == 'gutter_clean') {
                    setFranchise(prevFranchise => ({
                        ...prevFranchise,
                        gutter_ads_status: 'Pending'
                    }));
                    setSelectAdsToggle('Pending')
                }
            } else {
                SetFranchiseAdStatus(location, field, 'Off', toggleOffReason)
                if (field == 'roof_clean') {
                    setFranchise(prevFranchise => ({
                        ...prevFranchise,
                        roof_ads_status: 'Off'
                    }));
                    setSelectAdsToggle('Off')
                } else if (field == 'gutter_clean') {
                    setFranchise(prevFranchise => ({
                        ...prevFranchise,
                        gutter_ads_status: 'Off'
                    }));
                    setSelectAdsToggle('Off')
                }
            }
        }

    }

    const handleChange = (event) => {
        const value = event.target.value;
        setFranchise(prevFranchise => ({
            ...prevFranchise,
            recurring_leads_purchase: value == 'recurring'
        }));
        handlePaymentOption(value);
    };

    const handlePaymentOption = (value) => {
        const postRecurringStatus = async () => {
            let status = 'off'
            if (value == 'recurring') {
                status = 'on'
            }
            let response = PostRecurringStatus(location, value)
            toast.success(`Toggled recurring status ${status}!`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        }
        postRecurringStatus()
    };


    function toggleAd(field) {

        if (field == 'roof_clean') {
            if (franchise.roof_ads_status == 'Pending') {
                if(franchise.payment_invoice_url && franchise.payment_invoice_url != ''){
                    window.location.replace(franchise.payment_invoice_url)
                }else{
                    toast.warn('Your roof cleaning ads are currently pending setup - if this does not change in the next minute, please contact Mike', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                    });
                }
                return;
            }

            setSelectAdsToggle(franchise.roof_ads_status)
        } else if (field == 'gutter_clean') {
            if (franchise.gutter_ads_status == 'Pending') {
                if( franchise.payment_invoice_url && franchise.payment_invoice_url != ''){
                    window.location.replace(franchise.payment_invoice_url)
                }else{
                    toast.warn('Your gutter cleaning ads are currently pending setup - if this does not change in the next minute, please contact Mike', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                    });
                }
                return;
            }
            setSelectAdsToggle(franchise.gutter_ads_status)
        }

        setSelectAds(field)
        setOpenStripeModal(true)
    }

    return (
        <CssVarsProvider disableTransitionOnChange>

            <React.Fragment>
                <StripeLeadPaymentModal selectedAdToggled={selectedAdsToggle} selectedAd={selectedAds}
                                        franchise={franchise}
                                        parentCallback={handleCallback}
                                        openState={openStripeModal}></StripeLeadPaymentModal>
            </React.Fragment>

            <React.Fragment>
                <StripeBalanceTopUpModal
                    franchise={franchise}
                    parentCallback={handleCallback}
                    openState={openBalanceTopUpModal}></StripeBalanceTopUpModal>
            </React.Fragment>

            <CssBaseline/>
            <ToastContainer/>
            <Box sx={{display: 'flex', minHeight: '100dvh'}}>
                <Header/>
                <SideNav/>
                <Box
                    component="main"
                    className="MainContent"
                    sx={{
                        px: {xs: 2, md: 6},
                        pt: {
                            xs: 'calc(12px + var(--Header-height))',
                            sm: 'calc(12px + var(--Header-height))',
                            md: 3,
                        },
                        pb: {xs: 2, sm: 2, md: 3},
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth: 0,
                        height: '100dvh',
                        gap: 1,
                    }}
                >
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <Breadcrumbs
                            size="sm"
                            aria-label="breadcrumbs"
                            separator={<ChevronRightRoundedIcon/>}
                            sx={{pl: 0}}
                        >
                            <Link
                                underline="none"
                                color="neutral"
                                href="/dashboard"
                                aria-label="Home"
                            >
                                <HomeRoundedIcon/>
                            </Link>
                            <Link
                                underline="hover"
                                color="neutral"
                                href="/dashboard"
                                fontSize={12}
                                fontWeight={500}
                            >
                                Dashboard
                            </Link>

                            <Typography color="primary" fontWeight={500} fontSize={12}>
                                Lead Generation
                            </Typography>

                        </Breadcrumbs>
                    </Box>

                    {loadedData ? (
                        <Box sx={{
                            display: 'flex',
                            gap: 2,
                            alignItems: 'center',
                            flexWrap: 'wrap',
                            minWidth: '50%',
                            height: '100%',
                            justifyContent: 'center'
                        }}>
                            <CircularProgress
                                size="lg"
                            />
                        </Box>
                    ) : (

                        <>
                            {!franchise.stripe_account_linked ? (
                                <Stripe franchise={franchise}/>
                            ) : (
                                <>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            gap: 0.5,
                                            flexDirection: {xs: 'column', sm: 'column'},
                                            flexWrap: 'wrap',
                                        }}
                                    >


                                        <Typography level="h2" component="h1">
                                            Lead Generation
                                        </Typography>

                                        <Typography level="title-lg" color={"neutral"}>
                                            Your Balance: £{franchise.lead_balance}.0
                                            <Tooltip
                                                title={franchise.payment_invoice_url !== '' ? "Top-up is disabled because there is already an outstanding invoice." : ''}
                                                disableHoverListener={franchise.payment_invoice_url === ''}
                                            >
                                            <span>
                                                <Button
                                                    size="sm"
                                                    onClick={() => setOpenBalanceTopUpModal(true)}
                                                    startDecorator={<InsertLink/>}
                                                    variant="solid"
                                                    color="primary"
                                                    sx={{marginLeft: '10px'}}
                                                    disabled={franchise.payment_invoice_url !== ''}
                                                >
                                                    Click here to top up
                                                </Button>
                                            </span>
                                            </Tooltip>
                                        </Typography>
                                        <Typography level="body-sm" color="neutral">
                                            * Your balance needs to be over the £150 deposit to keep your ads on.
                                        </Typography>

                                        {franchise.stripe_customer_payment_method_linked && (
                                            <>
                                            <Divider sx={{mb: 1, mt: 1}}/>
                                            <FormControl>
                                                <Box sx={{display: 'flex'}}>
                                                    <Typography level="title-md" color={"neutral"}>Toggle Recurring Payments</Typography>
                                                    <Switch
                                                        variant="outlined"
                                                        sx={{marginLeft: 2}}
                                                        checked={franchise.recurring_leads_purchase}
                                                        onChange={(event) => handleChange({ target: { name: "radio-buttons-group", value: event.target.checked ? "recurring" : "one-time" } })}
                                                        slotProps={{
                                                            input: { 'aria-describedby': franchise.recurring_leads_purchase ? 'recurring-helper-text' : 'one-time-helper-text' }
                                                        }}
                                                    />
                                                </Box>

                                                <Typography style={{ marginTop: '5px' }} id={franchise.recurring_leads_purchase ? 'recurring-helper-text' : 'one-time-helper-text'} level="body-sm" color="neutral">
                                                    {franchise.recurring_leads_purchase ? (
                                                        <>We will attempt to automatically charge your saved card once your balance runs out to keep your ads running</>
                                                    ) : (
                                                        <>Your ads will <span style={{ fontWeight: '500' }}>TURN OFF</span> after your balance has ran out.</>
                                                    )}
                                                </Typography>
                                            </FormControl>
                                                <Divider sx={{mb: 1, mt: 1}}/>

                                            </>

                                        )}
                                    </Box>
                                    {!lastPaymentSuccess ? (
                                        <Card sx={{
                                            paddingTop: '5px',
                                            paddingBottom: '5px',
                                            textAlign: 'center'
                                        }}>
                                            <Typography level={"title-md"}>Your ads are
                                                turned OFF as your last payment
                                                didnt
                                                go
                                                through</Typography>
                                            <Button onClick={() => {
                                                createPaymentLink()
                                            }} sx={{marginTop: '5px'}}>Click here to confirm payment</Button>
                                        </Card>
                                    ) : (
                                        <>
                                            <Tabs
                                                aria-label="Pipeline"
                                                value={index}
                                                onChange={(event, value) => setIndex(value)}
                                            >
                                                <TabList
                                                    sx={{
                                                        justifyContent: 'left',
                                                        [`&& .${tabClasses.root}`]: {
                                                            flex: 'initial',
                                                            bgcolor: 'transparent',
                                                            '&:hover': {
                                                                bgcolor: 'transparent',
                                                            },
                                                            [`&.${tabClasses.selected}`]: {
                                                                color: 'primary.plainColor',
                                                                '&::after': {
                                                                    height: 2,
                                                                    borderTopLeftRadius: 3,
                                                                    borderTopRightRadius: 3,
                                                                    bgcolor: 'primary.500',
                                                                },
                                                            },
                                                        },
                                                    }}
                                                >
                                                    <Tab indicatorInset>
                                                        <Typography endDecorator={<AdsClickOutlined/>} fontWeight={400} fontSize={16}>Active Ads{' '}</Typography>
                                                    </Tab>
                                                    <Tab indicatorInset>
                                                        <Typography endDecorator={<PaymentOutlined/>} fontWeight={400} fontSize={16}>Past Payments{' '}</Typography>
                                                    </Tab>
                                                </TabList>
                                                <Box
                                                    sx={(theme) => ({
                                                        '--bg': theme.vars.palette.background.surface,
                                                        background: 'var(--bg)',
                                                        boxShadow: '0 0 0 100vmax var(--bg)',
                                                        clipPath: 'inset(0 -100vmax)',
                                                    })}
                                                >
                                                    <TabPanel value={0}>
                                                        <Box sx={{display: 'flex', flexDirection: 'column', width: '100%'}}>
                                                            <Typography fontWeight={500} fontSize={22}>
                                                                Active Ads
                                                            </Typography>
                                                            <Typography fontWeight={400} fontSize={16} sx={{mb: 1}} color={"neutral"}>
                                                                Toggle what ads are currently active
                                                            </Typography>

                                                            <Box sx={{width: '100%', display: 'flex'}}>
                                                                <Card orientation="horizontal"
                                                                      sx={{
                                                                          background: 'white',
                                                                          cursor: 'pointer',
                                                                          width: '500px',
                                                                          marginRight: 2,
                                                                      }}
                                                                      onClick={(event) => {
                                                                          toggleAd('roof_clean')
                                                                      }}>
                                                                    <CardOverflow
                                                                        variant="soft"
                                                                        color={
                                                                            franchise.roof_ads_status === 'Pending' ? 'warning' :
                                                                                franchise.roof_ads_status === 'Off' ? 'danger' :
                                                                                    'success'
                                                                        }
                                                                        sx={{
                                                                            px:1,
                                                                            writingMode: 'sideways-lr',
                                                                            justifyContent: 'center',
                                                                            fontSize: 'xs',
                                                                            fontWeight: 'xl',
                                                                            letterSpacing: '1px',
                                                                            textTransform: 'uppercase',
                                                                            borderLeft: '1px solid',
                                                                            borderColor: 'divider',
                                                                        }}
                                                                    >
                                                                        {franchise.roof_ads_status}
                                                                    </CardOverflow>
                                                                    <Box sx={{display: 'flex', flexDirection: 'column', mt: 'auto', mb: 'auto'}}>
                                                                        <Typography level="title-md">Roof Cleaning Ads</Typography>
                                                                        <Typography
                                                                            color="neutral"
                                                                            level="body-xs">(Google & Facebook)</Typography>
                                                                    </Box>
                                                                    <Box
                                                                        sx={{
                                                                            display: 'flex',
                                                                            flexDirection: 'row',
                                                                            justifyContent: 'center',
                                                                            alignItems: 'center',
                                                                            gap: 2,
                                                                            height: '50px',
                                                                            marginLeft: 'auto',
                                                                            '& > *': {
                                                                                height: '50px',
                                                                                padding: '0 15px',
                                                                                display: 'flex',
                                                                                alignItems: 'center',
                                                                            },
                                                                        }}
                                                                    >
                                                                        <Button sx={{padding: '15px'}}>Manage</Button>
                                                                    </Box>

                                                                </Card>
                                                                <Card  orientation="horizontal"
                                                                      sx={{
                                                                          background: 'white',
                                                                          cursor: 'pointer',
                                                                          width: '500px',
                                                                      }}
                                                                      onClick={(event) => {
                                                                          toggleAd('gutter_clean')
                                                                      }}>
                                                                    <CardOverflow
                                                                        variant="soft"
                                                                        color={
                                                                            franchise.gutter_ads_status === 'Pending' ? 'warning' :
                                                                                franchise.gutter_ads_status === 'Off' ? 'danger' :
                                                                                    'success'
                                                                        }
                                                                        sx={{
                                                                            px: 1,
                                                                            writingMode: 'sideways-lr',
                                                                            justifyContent: 'center',
                                                                            fontSize: 'xs',
                                                                            fontWeight: 'xl',
                                                                            letterSpacing: '1px',
                                                                            textTransform: 'uppercase',
                                                                            borderLeft: '1px solid',
                                                                            borderColor: 'divider',
                                                                        }}
                                                                    >
                                                                        {franchise.gutter_ads_status}
                                                                    </CardOverflow>
                                                                    <Box sx={{display: 'flex', flexDirection: 'column', mt: 'auto', mb: 'auto'}}>
                                                                        <Typography level="title-md">Gutter Cleaning Ads</Typography>
                                                                        <Typography
                                                                            color="neutral"
                                                                            level="body-xs">(Google & Facebook)</Typography>
                                                                    </Box>
                                                                    <Box
                                                                        sx={{
                                                                            display: 'flex',
                                                                            flexDirection: 'row',
                                                                            justifyContent: 'center',
                                                                            alignItems: 'center',
                                                                            marginLeft: 'auto',
                                                                            gap: 2,
                                                                            height: '50px',
                                                                            '& > *': {
                                                                                height: '50px',
                                                                                padding: '0 15px',
                                                                                display: 'flex',
                                                                                alignItems: 'center',
                                                                            },
                                                                        }}
                                                                    >
                                                                        <Button sx={{padding: '15px'}}>Manage</Button>
                                                                    </Box>

                                                                </Card>
                                                            </Box>

                                                        </Box>
                                                    </TabPanel>
                                                    <TabPanel value={1}>
                                                        <Box>
                                                            <Typography fontWeight={500} fontSize={22}>
                                                                Past Payments
                                                            </Typography>
                                                            <Typography fontWeight={400} fontSize={16} sx={{mb: 1}} color={"neutral"}>
                                                                A table of top-ups and charges towards your balance
                                                            </Typography>

                                                            <PastPaymentsTable/>
                                                        </Box>
                                                    </TabPanel>
                                                </Box>
                                            </Tabs>



                                        </>
                                    )}

                                </>
                            )}
                        </>
                    )}


                </Box>
            </Box>


        </CssVarsProvider>
    )

}