import * as React from 'react';
import {useEffect} from 'react';
import IconButton from '@mui/joy/IconButton';
import Table from '@mui/joy/Table';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import Box from "@mui/joy/Box";
import {KeyboardArrowLeft, KeyboardArrowRight, Message, PhoneCallback} from "@mui/icons-material";
import {CircularProgress} from "@mui/joy";
import {GetAllAdBalanceChanges} from "../../axios/Axios";

function formatDate(old_date) {
    let date = new Date(old_date);

    // Check if the date is valid
    if (!isNaN(date.getTime())) {
        // Date is valid, format it
        return date.toLocaleString('en-US', {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric'
        });
    } else {
        return 'No Valid Date';
    }
}

interface Row {
    amount: string;
    new_balance: string;
    created_at: string;
    type: string;
    service: string;
    id: number;
}


function Row(props: { row: Row}) {
    const {row} = props;

    return (
        <React.Fragment>
            <tr style={{width: '100%', background: row.type === 'Charge' ? 'Top Up' : 'rgba(13,255,0,0.09)'}}>
                <td>{row.id}</td>
                <td>{formatDate(row.created_at)}</td>
                <td>£{row.amount}</td>
                <td>{row.service}</td>
                <td>{row.type}</td>
                <td>£{row.new_balance}</td>
            </tr>
        </React.Fragment>
    );
}

const mapRawDataToRow = (data: any[]): Row[] => {
    return data.map(item => ({
        amount: item.amount,
        created_at: item.created_at,
        type: item.type,
        service: item.service,
        id: item.id,
        new_balance: item.new_balance,
    }));
};



function labelDisplayedRows({
                                from,
                                to,
                                count,
                            }: {
    from: number;
    to: number;
    count: number;
}) {
    return `${from}–${to} of ${count !== -1 ? count : `more than ${to}`}`;
}

export default function PastPaymentsTable() {
    const [loadedData, setLoadingData] = React.useState(false);
    const [rows, setRows] = React.useState <Row[]>([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(15);
    const [pageCount, setPageCount] = React.useState(0);


    const getLabelDisplayedRowsTo = () => {
        if (pageCount === -1) {
            return (page + 1) * rowsPerPage;
        }
        return rowsPerPage === -1
            ? pageCount
            : Math.min(pageCount, (page + 1) * rowsPerPage);
    };

    const handleChangePage = (newPage: number) => {
        setPage(newPage);
    };

    useEffect(() => {
        if (!loadedData) {
            return
        }

        setLoadingData(false)
        const fetchData = async () => {
            let location = localStorage.getItem('location')?.toLowerCase().replace(' ', '_')
            let adBalanceChanges = await GetAllAdBalanceChanges([location], page + 1);
            setRows(mapRawDataToRow(adBalanceChanges['results']))
            setPageCount(adBalanceChanges['count'])

            setLoadingData(true)
        };
        fetchData()
    }, [page]);

    useEffect(() => {

        setLoadingData(false)

        const fetchData = async () => {
            let location = localStorage.getItem('location')?.toLowerCase().replace(' ', '_')
            let adBalanceChanges = await GetAllAdBalanceChanges([location], page + 1);
            setRows(mapRawDataToRow(adBalanceChanges['results']))
            setPageCount(adBalanceChanges['count'])

            setLoadingData(true)
        };
        fetchData()
    }, []);


    return (
        <>
            <Sheet>

                <Table
                    hoverRow
                    aria-label="collapsible table"
                    sx={{
                        height: '100%',
                        width: '100%',
                        '& > thead > tr > th:nth-child(n + 3), & > tbody > tr > td:nth-child(n + 3)':
                            {textAlign: 'right'},
                        '& > tbody > tr:nth-child(odd) > td, & > tbody > tr:nth-child(odd) > th[scope="row"]':
                            {
                                borderBottom: 0,
                            },
                    }}
                >
                    <thead>
                    <tr style={{width: '100%'}}>
                        <th>ID</th>
                        <th style={{width: '300px'}}>Date</th>
                        <th>Amount</th>
                        <th>Service</th>
                        <th>Type</th>
                        <th>New Balance</th>
                    </tr>
                    </thead>
                    {loadedData ?
                        <>
                            <tbody>
                            {rows.map((row, index) => (
                                <Row key={index} row={row}/>
                            ))}
                            </tbody>
                            <tfoot>
                            <tr>
                                <td colSpan={6}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: 2,
                                            justifyContent: 'flex-end',
                                        }}
                                    >
                                        <Typography textAlign="center" sx={{minWidth: 80}}>
                                            {labelDisplayedRows({
                                                from: pageCount === 0 ? 0 : page * rowsPerPage + 1,
                                                to: getLabelDisplayedRowsTo(),
                                                count: pageCount === -1 ? -1 : pageCount,
                                            })}
                                        </Typography>
                                        <Box sx={{display: 'flex', gap: 1}}>
                                            <IconButton
                                                size="sm"
                                                color="neutral"
                                                variant="outlined"
                                                disabled={page === 0}
                                                onClick={() => handleChangePage(page - 1)}
                                                sx={{bgcolor: 'background.surface'}}
                                            >
                                                <KeyboardArrowLeft/>
                                            </IconButton>
                                            <IconButton
                                                size="sm"
                                                color="neutral"
                                                variant="outlined"
                                                disabled={
                                                    pageCount !== -1
                                                        ? page >= Math.ceil(pageCount / rowsPerPage) - 1
                                                        : false
                                                }
                                                onClick={() => handleChangePage(page + 1)}
                                                sx={{bgcolor: 'background.surface'}}
                                            >
                                                <KeyboardArrowRight/>
                                            </IconButton>
                                        </Box>
                                    </Box>
                                </td>
                            </tr>
                            </tfoot>
                        </>
                        :
                        <tbody>
                        <tr style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                            position: 'absolute',
                            marginTop: '50px',
                        }}>
                            <CircularProgress
                                size="lg"
                            />
                        </tr>
                        </tbody>
                    }
                </Table>

            </Sheet>

        </>


    );
}